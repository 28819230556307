import * as ActionTypes from "../Actions/admin"
import * as AuthTypes from "../Actions/auth";

const initialState = {
    authenticating: false,
    authenticated: false,
    connecting: false,
    connected: false,
    errorAuth: false,
    linked: false,
    linking: false,
    link: "0", 
    showLogin: false,
    showRegistration: false,
    showLinking: false,
    showLink: false,
    currentUser: {
        linked: false,
        username: '',
        id: null,
        role: ''
    }, 
    me: {},
    profile: null,
    accounts: [],
    users: [],
    currentProfile: {
        id: null
    }
}

export default (state = initialState, action) => {
    switch(action.type){
        case ActionTypes.REQUEST_CONNECT:
            return {
                ...state, 
                connecting: action.connecting,
                connected: action.connected
            }
        case ActionTypes.CONNECT:
            return {
                ...state, 
                connecting: action.connecting,
                connected: action.connected
            }
        case ActionTypes.DISCONNECT:
            return {
                ...state, 
                connecting: true, 
                connected: false
            }
        case ActionTypes.ME:
            return {
                ...state,
                me: {
                    ...action.me
                }
            }
        case AuthTypes.REQUEST_AUTH:
            return {
                ...state,
                authenticating: true
            }
        case AuthTypes.RESPONSE_AUTH:
            return {
                ...state,
                authenticating: false,
                authenticated: action.authenticated, 
                errorAuth: action.errorAuth,
                showLogin: action.showLogin,
                currentUser: {
                    ...action.user
                },
                accounts: [action.user.profile],
                currentProfile: {
                    ...action.user.profile
                },
                linked: action.linked
            }
        case AuthTypes.FAIL_AUTH:
            return {
                ...state,
                authenticating: false,
                authenticated: false,
                errorAuth: action.errorAuth
            }
        case AuthTypes.SHOW_LOGIN:
            return {
                ...state,
                showLogin: action.showLogin,
                errorAuth: action.errorAuth
            }
        case AuthTypes.SHOW_REGISTRATION:
            return {
                ...state,
                showRegistration: action.showRegistration,
                errorAuth: action.errorAuth
            }    
        case AuthTypes.SHOW_LINKING:
            return {
                ...state,
                showLinking: action.showLinking,
                errorAuth: action.errorAuth, 
                linking: false,
                link: "0", 
            }   
        case AuthTypes.FAIL_LINK: 
            return {
                ...state, 
                linking: false,
                errorAuth: action.errorAuth
            } 
        case AuthTypes.REQUEST_LINKING:
            return {
                ...state,
                linking: true
            }
        case AuthTypes.RESPONSE_LINKING:
            return {
                ...state, 
                linking: true, 
                errorAuth: action.errorAuth,
                showLink: action.showLink,
                link: action.link
            }
        case AuthTypes.RESPONSE_LINKING:
            return {
                ...state, 
                linking: true, 
                errorAuth: action.errorAuth,
                showLink: action.showLink,
                link: action.link
            }
        case AuthTypes.LOGOUT:
            console.log("LOGGING OUT ");
            return {
                ...initialState
            }
        case "READ_ERROR": 
            return {
                ...state, 
                errorAuth: ""
            }
        default: 
            return state
    }

}