import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {CardActions} from '@material-ui/core';
import ImageUpload from "../../../Containers/ImageUpload"

const styles = theme => ({
    root: {

    },    
    icon: {
        marginRight: theme.spacing(1)
    },
    flexGrow: {
      flex: '1 1 auto',
    }
    
})


class BottomTools extends React.Component{

    handleFiles = (e) => {
        if(e.target.files.length === 0) return;
        const files = [...e.target.files]
        this.props.handleDrop(null, files)
    };

    render(){
        const classes = this.props.classes;
        const {showActionOne, nameActionOne, publishDocument, actionName, saveDraft, allowUpload, authenticated} = this.props
        return (
            <CardActions >
                <div className={classes.flexGrow} />
                {(showActionOne && authenticated) &&
                    <Button
                        className={classes.button}
                        onClick={saveDraft}
                    >
                        {nameActionOne}
                    </Button>
                }
                {(authenticated && actionName) && 
                    <Button
                        onClick={publishDocument}
                    >
                        {actionName}
                    </Button>
                }
            </CardActions>
        )
    }
}


export default withStyles(styles)(BottomTools);