import React from "react";
import {
    Typography,
    Grid,
    withStyles,
    Button
} from '@material-ui/core';
import moment from 'moment';
import StatBox from "../../Components/StatBox.js";
import {Link} from "react-router-dom";
import Elo from "../Containers/Elo";
import Matches from "../Containers/Matches";
import MaterialSwitch from '@material-ui/core/Switch';
const styles = theme => ({
    root: {
      flexGrow: 1,
       
        '&:after': {
            content: '""',
            height: '105px',
            width: "100vw",
            display: "block"
        }
    },
    header: {
      padding: theme.spacing(2),
    },
    stat: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    statName: {
        flex: 1,
        alignSelf: "flex-end"
    },
    userData: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: "center",
        '& > *': {
          margin: theme.spacing(1),
          width: theme.spacing(14),
          height: theme.spacing(14),
        }
    },
    text: {
        color: theme.palette.text.primary
    },
    subheader: {
        color: theme.palette.text.secondary
    }
  });



class UserPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {ranked: false};
      }

      onCheckChange = (e) => {
        this.setState({
            ranked: e.target.checked
        })
    }
    componentDidMount(){
        this.props.getUserAccount();
    }

    componentDidUpdate(prevProps){
        if(prevProps.userId != this.props.userId){
            this.props.getUserAccount();
        }
    }



    render() {

        const {user, loading, classes, authenticated, linked, currentProfile} = this.props;

        const {ranked} = this.state;

        const username = () => {
            return(
                <Typography variant="h2" className={classes.text} style={{fontWeight: "500"}}>
                    {user.userName}
                </Typography>
            )

        }

        var timeSince = () => {
            return (
                <Typography type="subheading" className={classes.subheader}>
                    Last Online: {moment(user.lastOnline).fromNow()}
                </Typography> 
            )
        }


        const buildStats = (u) => {
            return [
                {
                    title: "ELO",
                    value: u.elo
                },
                {
                    title: "RANK",
                    value: u.rank
                },
                {
                    title: "WINS",
                    value: u.wins
                },
                {
                    title: "LOSSES",
                    value: u.losses
                }, 
                {
                    title: "WIN %",
                    value: `${ Math.round( (u.wins / (u.wins + u.losses) ) * 100 ) }%`
                }
            ]
        }


        let link = "";

        if(user != undefined){
            link = (!this.state.ranked) ? `/accounts/${user.id}/matches` : `/accounts/${user.id}/matches/ranked`
        }

        return (
            <Grid container className={classes.root}>
                {(!loading && user != undefined) && 
                        <Grid item xs={12} >
                            <div className={classes.header}>
                                {username()}
                                {timeSince()}
                            </div>
                            <Elo id={user.id}/>
                            {linked && authenticated &&
                                <div className = {classes.header}>
                                    {currentProfile.id != user.id &&
                                        <Button variant="contained" color="secondary" component={Link} to={`/matchup/${user.id}/${currentProfile.id}`}>
                                            MATCHUP STATS
                                        </Button>
                                    }

                                </div>
                            }

                            <div className={classes.header}>
                                <Typography variant="h3" className={classes.text}>
                                    Stats
                                </Typography>
                            </div>
                            <Grid container className={classes.userData}>
                                <StatBox stats={buildStats(user)} />
                            </Grid>
                            <div className={classes.header}>
                                <Typography variant="h3" className={classes.text}>
                                    Match History
                                </Typography>
                            </div>
                            <div className={classes.header}>
                                <Typography variant="h5" className={classes.text}>
                                    Ranked Only
                                </Typography>
                                <MaterialSwitch
                                    checked={this.state.ranked}
                                    onChange={this.onCheckChange}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </div>
   
                            <Matches linkPath={link} />
                        </Grid> 
                }
                {loading && 
                    <Typography variant="h1" color="textPrimary" >LOADING</Typography>
                }
            </Grid>

            

        )
    }
}

export default withStyles(styles)(UserPage);


// {currentProfile.id == user.id && 
//     <Button variant="contained" color="secondary" component={Link} to={`/eleven/links/new`}>
//         Generate Link
//     </Button>
// }