import {connect} from "react-redux";
import {CreateComment} from "../Actions/commentActions";
import CommentForm from "../Components/Comments/CommentForm.js";

const commentProps = (state, saving) => (
    {
        saving: saving,
        authenticated: state.Auth.authenticated,
        user_id: state.Auth.currentUser.id        
    }
)

const mapStateToProps = (state, ownProps) => {
    const {meta} = state.data
    if(meta[ownProps.commentLink]){
        return commentProps(state, meta[ownProps.commentLink].saving)
    }
    return commentProps(state, false);
} 

const mapDispatchToProps = (dispatch) => {
    return {
        createComment: (comment) => {
            return dispatch( CreateComment(comment) )
        }
    }
}

const NewComment = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentForm);

export default NewComment;