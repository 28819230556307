import React from 'react';
import AuthForm from "./AuthForm";
import { TextField, Button, Typography, FormControl, FormHelperText, withStyles} from '@material-ui/core';

import Eleven from "../../Clients/Eleven";

const styles = theme => ({
    contentLinked: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${205}px)`
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  })


class ForgotPasswordForm extends React.Component{
    state = {
        password: '',
        passwordConfirmation: '',
        loading: false
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    closeForm = () => {
        console.log("CLOSING");
    }

    handleSubmit = (e) => {
        const {password, passwordConfirmation} = this.state;
        const {token} = this.props.match.params;

        this.setState({loading: true})

        Eleven.post(`/auth/passwordresetverify/${token}`, {
            body: {
                user: {
                    password: password,
                    password_confirmation: passwordConfirmation
                }
            }
        })
            .then(res => {
                this.setState({loading: false})
                this.props.history.push("/eleven")
                this.props.showLogin();
            })
            .catch(err => {
                console.log(err);
                this.setState({loading: false})
            })
    }


    render(){
        const {loading} = this.state;
        return (
                <AuthForm
                    actions={[
                        <Button
                            key="loginbutton"
                            variant="contained"
                            onClick={this.handleSubmit}
                            color="primary"
                            disabled={loading}
                        >
                            Link Account
                        </Button> 
                    ]}
                    logo="ELEVEN VR"
                    text="Reset Password"
                    open={true}
                    closeForm={this.closeForm}
                    >
                          <>
                            <TextField
                                fullWidth
                                type="password"
                                label="Password"
                                name="password"
                                required
                                onInput={this.handleInput}
                                value={this.state.password}  
                                margin="normal"                 
                            />
                            <TextField
                                fullWidth
                                type="password"
                                label="Confirm Password"
                                name="passwordConfirmation"
                                required
                                onInput={this.handleInput}
                                value={this.state.passwordConfirmation}  
                                margin="normal"                 
                            />
                        </>
  

            </AuthForm>
                    
        )
    }
}

export default withStyles(styles)(ForgotPasswordForm);