import React from "react";

import { withStyles, Typography, Avatar } from '@material-ui/core';

import Default from "./default.png"

import one from "./AvatarMedia/1.png"
import two from "./AvatarMedia/2.png"
import three from "./AvatarMedia/3.png"
import four from "./AvatarMedia/4.png"
import five from "./AvatarMedia/5.png"
import six from "./AvatarMedia/6.png"
import seven from "./AvatarMedia/7.png"
import eight from "./AvatarMedia/8.png"

export const AvatarMedia = [
    one, two, three, four, five, six, seven, eight
]

const styles = theme => ({
    avatar: {
        margin: theme.spacing.unit
    },
})
const UserAvatar = ({user, classes, avatar}) => {
    let url = user.avatarUrl || Default;
    return (
            <Avatar 
                className={(avatar) ? avatar : classes.avatar}
                src={url}
            />            
    )
}

export default withStyles(styles)(UserAvatar);

export const UserHeader = ({user, subtitle}) => {
    return (
        <UserInfo
            style={{textAlign: "left"}}
            user={user}
            timestamp={<SubTitle subtitle={subtitle}/> }
        />    
    )
}

const userNameStyles = theme => ({
    
    chip: {
        display: 'inline-block',
        margin: theme.spacing.unit
    },
    userInfo: {
        color: theme.palette.text.secondary,
        textAlign: "left"
    }
})


const UserName = ({user, timestamp, classes}) => {
    return (
        <span>
            <Typography variant="subtitle1" display="block" className={classes.userInfo}>
                {user.username}
            </Typography>
            <Typography variant="caption" display="block" className={classes.userInfo}> 
                {timeSince(timestamp)}
          </Typography>            
        </span>          
    )
}

const UserInfo = withStyles(userNameStyles)(UserName)
export {UserInfo}

export const SubTitle = ({subtitle, fontStyle, subClass}) => {
    let subTitle = '';
    let className = subClass + ' avatar-subtitle';
    if(subtitle && validateDate(subtitle)){
        subTitle = timeSince(subtitle)
    }
    return (
        <span style={fontStyle} className={className}>{subTitle}</span>
    )
}

export const Role = ({user}) => {
    if(user.role === "admin"){
        return (
            <div style={{display: 'inline-block'}}>
                {user.username}
            </div>
        )
    } else {
        return (
            <span>{user.username}</span>
        )
    }
}



function validateDate(timestamp){
    return new Date(timestamp).getTime() > 0;
}

var DURATION_IN_SECONDS = {
  epochs: ['year', 'month', 'day', 'hour', 'minutes'],
  year: 31536000,
  month: 2592000,
  day: 86400,
  hour: 3600,
  minute: 60
};

function getDuration(seconds) {
  var epoch, interval;

  for (var i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
    epoch = DURATION_IN_SECONDS.epochs[i];
    interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
    if (interval >= 1) {
      return {
        interval: interval,
        epoch: epoch
      };
    }
  }

};

function timeSince(date) {
    if(!date){
        return;
    }
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var duration = getDuration(seconds);
  if(!duration) return "Just Now";
  var suffix = (duration.interval > 1 || duration.interval === 0) ? 's' : '';
  return duration.interval + ' ' + duration.epoch + suffix + " ago";
};

