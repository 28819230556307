import {connect} from "react-redux";
import {Authenticate, ShowLogin} from "../../Actions/auth";
import Login from "../../Components/Auth/Login/Login.js";
import Config from "../../Config/Config";


const mapStateToProps = (state) => {
    return {
        authenticated: state.Auth.authenticated,
        authenticating: state.Auth.authenticating,
        showLogin: state.Auth.showLogin
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        authenticateUser: (userCredentials) => {
            return dispatch( Authenticate(userCredentials) )
        },
        closeLogin: () => {
            return dispatch(ShowLogin(false))
        },
        oculusLogin: () => {
            localStorage.setItem('oculusRedirect', ownProps.location.pathname);
        
            window.location.href = `https://auth.oculus.com/sso/?redirect_uri=${Config.oculusRedirect}&organization_id=${Config.oculusOrgId}`;
        }
    }
}


const AuthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default AuthContainer;
