import axios from "axios";
import Config from "../Config/Config.js";
const Eleven = axios.create({
    baseURL: Config.baseUrl,
    headers: {'Authorization': localStorage.getItem("refreshToken")}
});

const ElevenHub = axios.create({
    baseURL: Config.hubUrl

});

// Add a request interceptor
Eleven.interceptors.request.use(config => {
  // Assuming `config.params` is either undefined or an object
  config.params = config.params || {};
  config.params['api-key'] = 'gyghufjiuhrgy783ru293ihur8gy'; // Add your API key query param

  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});




export {Eleven, ElevenHub};
export default axios;
