import normalize from 'json-api-normalizer';
import Config from "../Config/Config.js";
export const API_ROOT = Config.baseUrl;

export const API_DATA_REQUEST = 'API_DATA_REQUEST';
export const API_DATA_SUCCESS = 'API_DATA_SUCCESS';
export const API_DATA_FAILURE = 'API_DATA_FAILURE';

function callApi(endpoint, options = {}) {
  var Url = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;
  if(!Url.includes('api-key')){
	  var separator = Url.includes('?') ? '&' : '?';
	  Url = Url+separator+'api-key=gyghufjiuhrgy783ru293ihur8gy';
  }
  const fullUrl = Url;
  return fetch(fullUrl, options)
              .then((res) => {
                if (!res.ok) {
                  return Promise.reject(res);
                }
                return res.json()
              })
              .then((json) => {

                return Object.assign({}, normalize(json, { endpoint: fullUrl, method: options.method }));
              })
              .catch((err) => {
                console.log(err);
                return Promise.reject(err);
              })

  // return fetch(fullUrl, options)
  //   .then(response => response.json()
  //     .then((json) => {
  //       console.log(json);
  //       if (!response.ok) {
  //         return Promise.reject(json);
  //       }
  //       return Object.assign({}, normalize(json, { endpoint: fullUrl, method: options.method }));
  //     }).catch(err => {
  //       console.log(err);
  //     })
  //   ).catch(err => {
  //     console.log(err);
  //     return Promise.reject(err);
  //   })
}

const defaultHeaders = () => ( 
  new Headers({
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem("refreshToken")
  })
);

function filterEndPoint(dp){
  var x = dp.indexOf('?');
  if(x != -1){
    console.log("found ?")
    return dp.substring(0, x);
  }
  return dp;
}

export const CALL_API = Symbol('Call API');

export default function (store) {
  return function nxt(next) {
    return function call(action) {
      const callAPI = action[CALL_API];
      if (typeof callAPI === 'undefined') {
        return next(action);
      }

      let { endpoint, options, clear } = callAPI;


      let method, headers;

      // set method and headers to default GET and defaultHeaders if options is empty
      // this gives me a chance to pass in auth token stored in localStorage
      if(typeof options === 'undefined'){
        method =  "GET";
        headers = defaultHeaders()
        options = {}
      }
      else {
        method = (options.method) ? options.method : "GET";
        headers = (options.headers) ? options.headers : defaultHeaders();
      }

      options = {...options, method, headers}

      if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
      }

      if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
      }

      if(clear === undefined){
        clear = false;
      }

      const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;

      const actionWith = (data) => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];
        return finalAction;
      };

      next(actionWith({ type: API_DATA_REQUEST, endpoint: filterEndPoint(fullUrl), method, clear }));

      return callApi(fullUrl, options || {})
        .then(
          response => {
            if(process.env.NODE_ENV === "development")
            {
              console.log(response);
            }

            return next(actionWith({ response, type: API_DATA_SUCCESS, endpoint: filterEndPoint(fullUrl), method, clear }))
          },
          error => next(actionWith({ type: API_DATA_FAILURE, endpoint: filterEndPoint(fullUrl), method, error: error || 'Something bad happened' })),
        );
    };
  };
}