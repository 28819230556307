import ParseMessage from "./ParseMsg";
const MAX_CONNECT_ATTEMPTS = 20;
const MIN_TIME_TO_RETRY = 3000;
const MAX_TIME_TO_RETRY = 180000; //3min
let firstMessage=false;
class Socket {
    socket = null;
    url = null;
    msgId = 1;
    resCallbacks = {};
    connectFail = 0;
    onConnect = null;
    onDisconnect = null;
    onFirstMessage =null;
    processLater = [];
    onMessageReceived = null;

    initialize(url){
        // We should only have one socket connection per client
        if(url){
            this.url = url;
        }
        if(this.socket) return;
        if(this.connectFail === 0){
            console.log("INITIALIZING SOCKET CONNECTION");
        }
        this.socket = new WebSocket(this.url);
        this.socket.onerror = this.onError;
        this.socket.onmessage = this.onMessage;
        this.socket.onopen = this.onOpen;
        this.socket.onconnect = this.onOpen;
        this.socket.onclose = this.onClose;

    }

    onMessage = (res) => {
        const _msg = JSON.parse(res.data);
        const _msgId = _msg.msgId;
        
        console.log("RECEIVING MESSAGE", _msg);


        if((_msgId === "0" || (!_msgId && !firstMessage)) && this.onMessageReceived){
            let msgOut = ParseMessage(_msg);
            return this.onMessageReceived(msgOut);
        }
        
        if(_msgId){
            const{callback, callName} = this.resCallbacks[_msgId];
            if(callback){
                _msg.messageType = callName;
                let msgOut = ParseMessage(_msg);
                console.log("THIS IS MSGOUT", msgOut)
                callback(_msg.error, msgOut);
                delete this.resCallbacks[_msgId];
            }
        }
        
        if(firstMessage){
            if(this.onFirstMessage){
                this.onFirstMessage(_msg);
            }
            firstMessage = false;
        }
    }

    onError = (msg) => {
        console.log("WEBSOCKET ERROR", msg);
    }

    onOpen = (msg) => {
        console.log("Initialized SOCKET CONNECTION");
        if(this.onConnect){
            this.onConnect(msg);
        }

        if(this.connectFail > 0){
            console.log("RESTABLISHED CONNECTION", this.connectFail);
        }
        this.connectFail = 0;

        if(this.processLater.length > 0){
            this.processLater.forEach((delayedMsg) => {
                this.socket.send(delayedMsg);
            })
            this.processLater = [];
        }
        firstMessage=true;
    }

    onClose = (msg) => {
        this.socket = null;
        
        this.connectFail++;

        let time = MIN_TIME_TO_RETRY;

        if(this.connectFail > MAX_CONNECT_ATTEMPTS){
            time = MIN_TIME_TO_RETRY * this.connectFail * this.connectFail;
            if(time > MAX_TIME_TO_RETRY){
                time = MAX_TIME_TO_RETRY;
            }
        }

        if(this.onDisconnect){
            this.onDisconnect(msg);
        }
        if(msg.code === 1000) return;
        
        
        setTimeout(() => {
            if(msg.reason && !msg.reason.startsWith("ERROR")){
                this.initialize();
            }
        }, time);


    }


    sendMessage = (msgInfo, callback) => {
        if(callback && typeof callback === "function"){
            this.resCallbacks[this.msgId] = {
                callback,
                callName: msgInfo.callName
            } 
        }

        const msg = JSON.stringify({
            ...msgInfo,
            msgId: this.msgId++, 
            msgTs: ((new Date() - 0)+ "")
        })

        console.log(msg, "MESSAGE TO SEND OUT")
        
        if(this.socket && this.socket.readyState === WebSocket.OPEN){
            this.socket.send(msg);
        } else {
            this.processLater.push(
                msg
            )
        }
        
    }

    closeSocket = () => {
        if(this.socket && this.socket.readyState === WebSocket.Open){
            this.socket.close(1000);
        }
        this.socket = null;
    }

    setOnConnect = (cb) => this.onConnect = cb;
    setOnDisconnect = (cb) => this.onDisconnect = cb;
    setOnFirstMessage = (cb) => this.onFirstMessage = cb;
    setOnGeneralMessage = (cb) => this.onMessageReceived = cb;
}

const SocketConnection = new Socket();
export default SocketConnection;