const InitialState = {
    open: false,
    activeLink: ''
}

export default (state=InitialState, action) => {
    switch (action.type){
        case "CLICKLINK":
            return {
                ...state,
                activeLink: action.link
            }
        case "TOGGLE":
            return {
                ...state,
                open: !state.open
            }
        default:
            return state;
    }
}