import React from "react";
import Link from "./Link";
import { withStyles, Hidden, withWidth} from '@material-ui/core';

import { withRouter } from 'react-router';
import compose from 'recompose/compose';

import classnames from 'classnames';


const styles = theme => ({
    link: {
        padding: [[0, theme.spacing.unit]],
        lineHeight: "44px",
        verticalAlign: 'baseline',
    },
    listItem: {
        display: 'inline-block',
        height: "44px",
        textAlign: "center",
        listStyleType: 'none'
    },
    list: {
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper, 
        margin: 0,
        minWidth: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        height: "44px",
        //marginTop: 56,
        [theme.breakpoints.up('sm')]: {
          //marginTop: 64,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: "100vw",
          },        
        boxShadow: theme.shadows[1],     
        zIndex: theme.zIndex.appBar,
        position: 'relative'
    },
    textClass: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.palette.text.primary,
        textDecoration: 'none',
    },
    activeClass: {
        color: theme.palette.action.primary,
    },
    flexGrow: {
        position: 'absolute',
        right: 60,
        bottom: 0
    }
})

class SubHeader extends React.Component {

    render(){
        const {links, match, classes, action} = this.props;
        let list = links.map((link) => {
            return(
                <li key={link.id} className={classes.listItem}>
                    <Link 
                        to={link.path}
                        className={classes.link}
                        textClass={classes.textClass}
                        activeClass={classes.activeClass}
                        exact
                        underline
                    >
                        {link.label.replace("_", " ")}
                    </Link>
                </li>
            )
        })        

        return (
            <ul className={classes.list} >
                {list}
                {action != null &&
                    <Hidden mdDown> 
                        <li key={"CREATE_ITEM"}  id="subheader-action" className={classnames(classes.listItem, classes.flexGrow)} >
                            {action}
                        </li>
                    </Hidden>
                }

            </ul>
        )
    }
}


export default compose(withRouter, withStyles(styles), withWidth())(SubHeader);