import React from "react";
import { withStyles, IconButton, CircularProgress, Paper } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import Editor from 'draft-js-plugins-editor';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import {
    EditorState, 
    convertToRaw, 
} from 'draft-js';
const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions } = emojiPlugin;
const plugins = [emojiPlugin];
const styles = theme => ({
    commentBox: {
      cursor: "text",
      backgroundColor: theme.palette.background.default,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      boxSizing: 'border-box',
      '& div[role=listbox]': {
          backgroundColor: theme.palette.background.paper
      }  
    },
    comment: {
        flex: '1 1 auto',
        maxWidth: '100%', 
    },  
    button: {
        height: 24,
        width: 24
    },
    icon: {
        height: 24,
        width: 24
    },
    actions: {
        height: 24,

    }

})


class CommentForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            saving: false
        }
    }

    focus = () => {
        this.editor.focus();
    };    

    onChange = (editorState) => {
        this.setState({editorState})   
    }

    saveComment = () => {
        const {editorState} = this.state;
        const comment = convertToRaw(editorState.getCurrentContent());
        const {createComment, commentLink, user_id} = this.props;
        this.setState({saving: true})
        createComment({user_id, commentLink, comment})
            .then(result => {
                this.setState({
                    saving: false,
                    editorState: EditorState.createEmpty()
                })
            })
    }

    render(){
        const {authenticated, classes} = this.props;
        const {editorState, saving} = this.state;
        return (
            <Paper className={classes.commentBox}>
                <div className={`${classes.comment} comment-editor`} onClick={this.focus}>
                    <Editor 
                        editorState={editorState}
                        placeholder="Write a comment..."
                        onChange={this.onChange}      
                        plugins={plugins}          
                        ref={(element) => { this.editor = element; }}                                                    
                    />
                    <EmojiSuggestions />
                </div>
                <div className={classes.actions}>
                    <IconButton className={classes.button} onClick={this.saveComment} disabled={!authenticated}>
                        {!saving && 
                            <Send className={classes.icon} />
                        }
                        {saving &&
                            <CircularProgress className={classes.icon} />
                        }
                    </IconButton>
                </div>
            </Paper>
        )
    }
}
/*
CommentForm.PropTypes = {
    commentable_id: PropTypes.number,
    commentable_type: PropTypes.string,
    saving: PropTypes.bool,
    createComment: PropTypes.func,
    authenticated: PropTypes.authenticated
}
*/
export default withStyles(styles)(CommentForm);