import React from 'react';
import Header from "./Header/Header.js";
import {Grid, withStyles, Hidden } from "@material-ui/core";
// import { CircularProgress } from 'material-ui/Progress';



const styles = theme => ({
  appFrame: {
    position: 'relative',
    width: '100%',
    height: "100%",
    backgroundColor: theme.palette.background.default,  
  },
  content: {
    overflowX: 'hidden',
    maxWidth: "100%",
    margin: 0,  
    overflowY: "scroll",
    maxHeight: "100vh", 
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 108px)'
    },
    [theme.breakpoints.down('xs')]: {
      height: "calc(100% - 112px)"
    }
  },
  rest: {
    height: "100%",
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 32px)'
    },
    [theme.breakpoints.down('xs')]: {
      '&:after': {
        content: '""',
        width: "100%",
        display: "block",
        height: "56px"
      },
    },
    '&:before': {
      content: '""',
      display: "block",
      width: "100vw",
      [theme.breakpoints.up('sm')]: {
        height: '64px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '56px'
      }
    }
  },
  marginTop: {
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
    },   
    height: 'calc(100% - 56px)',    
  },
  noMargin: {
    height: 'calc(100% - 96px)',
    padding: theme.spacing(1),
    overflowY: "scroll",
    overflowX: 'hidden',
    maxWidth: "100%",
    margin: 0,    
  }, 
  flex: {
    display: 'flex'
  },
});

class Wrapper extends React.Component{
    render(){
      const {children, header, sub, style, bottom} = this.props;
      const classes = this.props.classes;

      let justify = this.props.justify;

      if(justify == null){
        justify = "center";
      }
        return (
          <div className={classes.appFrame}>  
            {header && 
              header
            }
              {!header && 
                <Header />
              }   
            <div className={classes.rest}> 
              {sub && 
                sub 
              }   


              <Grid container justify={justify} className={classes.content} style={style}>
                {children}
              </Grid>

              <Hidden smUp implementation="css">
                {bottom && 
                  bottom 
                }
              </Hidden>
            </div>


          </div>
        )
    }
}

export default withStyles(styles)(Wrapper);