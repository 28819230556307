import {connect} from "react-redux";
import Comments from "../../Components/Comments/Comments.js";
import build from 'redux-object';
import { CALL_API, API_ROOT } from "../../Middleware/Api";

const mapStateToProps = (state, ownProps) => {
    const {meta} = state.data;

    const link = `${API_ROOT}${ownProps.commentLink}`

    console.log("GETTING COMMENTS" + link);

    if(meta[link]){
        const comments = (meta[link].data || []).map(object => build(state.data, 'comments', object.id))
        const loading = meta[link].loading;

        return {
            comments: comments.sort((d1, d2) => new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime() )  , 
            loading
        }

    }
    return {
        comments: [],
        loading: true
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadComments: () => {
            const link = `${API_ROOT}${ownProps.commentLink}`
            dispatch( {
                [CALL_API]:
                {
                    endpoint: link
                }
            } )
        }
    }
}

const CommentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);

export default CommentList;