import React from "react";
import { Title, FormatQuote, List, Code } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import IconButton  from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';

const BLOCK_TYPES = [
    {label: 'H2', style: 'header-one', icon: <Title />},
    {label: 'quote-right', style: 'blockquote', icon: <FormatQuote />},
    {label: 'list-ol', style: 'ordered-list-item', icon: <List />},
    {label: 'code', style: 'code-block', icon: <Code />},
];

const styles = theme => ({
    root: {

    },    
    icon: {
        margin: theme.spacing.unit
    }
})

class EditorToolBar extends React.Component{

    constructor() {
        super();
        this.onToggle = (style) => {
            this.props.onToggle(style);
        };
    }

    
    render(){

        const {editorState} = this.props;
        const selection = editorState.getSelection();        
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        const classes = this.props.classes;
        


        return (
            <Toolbar disableGutters className={classes.root}>
                    {BLOCK_TYPES.map((type) =>
                        <IconButton 
                            key={type.label}
                            color={(type.style === blockType) ? "primary" : "default"}
                            onMouseDown={ (e) => { 
                                e.preventDefault();
                                this.onToggle(type.style) 
                              }
                            } 
                        >
                            {type.icon}
                        </IconButton>                    
                    )}
            </Toolbar>
        )
    }
}


export default withStyles(styles)(EditorToolBar);