import {connect} from "react-redux";
import {GetUserAccountMatches} from "../../Actions/userAccountActions"
import { CALL_API, API_ROOT } from "../../Middleware/Api";

import UserSearchPage from "../Components/UserSearchPage";
import build from 'redux-object';
import moment from "moment";


const defaultProps = (acts, loading, next, onClick) => {
    return {
        accounts: acts,
        loading: loading,
        next: next, 
        onClick: onClick
    }
}

const mapStateToProps = (state, ownProps) => {
    const {search} = state.Category;

    const {meta} = state.data;
    const link = `${API_ROOT}/accounts/search/${search}`

    if(meta[link]){
        
        let users = (meta[link].data || []).map(m => {
            return build(state.data, 'userAccounts', m.id);
        });

        let next = (meta[link].links) ? meta[link].links.next : null;

        return defaultProps(users, meta[link].loading, next, ownProps.onUserClick)
    }

    return defaultProps([], true, null, ownProps.onUserClick);
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserAccounts: (username) => {
            dispatch( {
                type: "USER_SEARCH",
                search: username
            })
            if(username == null || username.length == 0){
                return;
            }
            dispatch( {
                [CALL_API]: {
                    endpoint: `${API_ROOT}/accounts/search/${username}`
                }
            })
        },
        getNext: (link) => {
            dispatch( {
                [CALL_API]: {
                    endpoint: link
                }
            })
        },
        clearSearch: () => {
            dispatch({
                type: "USER_SEARCH",
                search: ""
            })
        }
    }
}


const MatchesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearchPage);

export default MatchesPage;