import React, { Component } from 'react';
import Editor, {plugins, EmojiSuggestions, InlineToolbar, AlignmentTool} from "./PluginHelper"
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import Hidden from '@material-ui/core/Hidden';

import {Card, CardContent} from '@material-ui/core';
import EditorToolBar from "./Components/EditorToolbar";
import BottomToolBar from "./Components/BottomToolBar";

import "./Editor.css";

import { 
    getDefaultKeyBinding, 
    KeyBindingUtil,
    RichUtils,
    convertToRaw,
} from 'draft-js';

import classNames from 'classnames';

const {hasCommandModifier} = KeyBindingUtil;


// function getBlockStyle(block) {
//     switch (block.getType()) {
//     case 'blockquote': return 'news-admin-blockquote';
//     default: return null;
//     }
// }

function myKeyBindingFn(e) {
    if (e.keyCode === 83 && hasCommandModifier(e)) {
        return 'myeditor-save';
    }
    return getDefaultKeyBinding(e);
}

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit,
  },
  root: {

    position: 'relative',
    borderRadius: 0
  },
  content: {
      ...theme.typography.body2,
      cursor: "text",
      position: 'relative'
  },
  underline: {
    '&:before': {
        left: 0,
        bottom: 0,
        content: '""',
        height: 1,
        position: 'absolute',
        right: 0,
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.ease,
        }),
        pointerEvent: 'none', // Transparent to the hover style.
    }, 
    "&:hover:before": {
        backgroundColor: theme.palette.text.primary,
        height: 2,
    },
  },    
    inkbar: {
        '&:after': {
            backgroundColor: theme.palette.primary[theme.palette.type === 'light' ? 'A700' : 'A200'],
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            height: 2,
            position: 'absolute',
            right: 0,
            transform: 'scaleX(0)',
            transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeOut,
            }),
            pointerEvent: 'none', // Transparent to the hover style.
        },
        '&$focused:after': {
            transform: 'scaleX(1)',
        },
    }, 
    focused: {},       
});

class DefaultEditor extends Component {

    constructor(props){
        super(props)
        this.state = {focused: false};        
    }

    focus = () => { this.refs.editor.focus(); };

    toggleBlockType = (blockType) => {
        this.props.onUpdate(
            RichUtils.toggleBlockType(
                this.props.editorState,
                blockType
            )
        );
    }
    handleKeyCommand = (command) => {
        if (command === 'myeditor-save') {
            this.saveDraft();
            return 'handled';
        }
        return 'not-handled';
    }

    saveDraft = () => {
        if(!this.props.saveDocument) return;
        const {editorState} = this.props;
        const contentState = editorState.getCurrentContent();    
        const plaintext = contentState.getPlainText();        
        this.props.saveDocument(convertToRaw(contentState), plaintext)        
    }

    publishDocument = () => {
        if(!this.props.publishDocument) return;
        const {editorState} = this.props;
        const contentState = editorState.getCurrentContent();
        const plaintext = contentState.getPlainText();      
        this.props.publishDocument(convertToRaw(contentState), plaintext, true)           
    }

    handleDrop = (selection, files) => {
        if(this.props.readOnly) return;
        if(this.uploadManager){
            this.uploadManager.uploadFile(files[0]);
        }
    }
    onFocus = (e) => {
        this.setState({focused: true})
    }
    onBlur = (e) => {
        this.setState({focused: false})
    }

    render() {

        // flags (booleans)

        //TODO: Take all these flags and seperate them into groups, top, full, content, bottom
        
        const {onUpdate, editorState, saving, actionName, allowUpload, hidePublish, authenticated, hideTools, showActionOne, nameActionOne, placeholder} = this.props;
        const classes = this.props.classes;


        // HIDE PLACEHOLDER
        var contentState = editorState.getCurrentContent();
        let editorClass = ' editor'

        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                editorClass += ' editor-hidePlaceholder ';
            }
        }
        return (
            <Card className={classNames('full-editor', classes.root, classes.underline, classes.inkbar, {
                [classes.focused]: this.state.focused
            })} onClick={this.focus}>
                {(!hideTools) &&
                        <EditorToolBar
                            editorState={editorState}
                            onToggle={this.toggleBlockType}
                        />
                    }
                <CardContent className={classNames(editorClass, classes.content)}>
                    <Editor
                        editorState={editorState}
                        onChange={onUpdate}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        plugins={plugins}
                        ref="editor"
                        handleDroppedFiles={this.handleDrop}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={myKeyBindingFn}
                        spellCheck={true}
                        placeholder={placeholder}
                    />
                        <InlineToolbar />
                        <EmojiSuggestions />
                        <AlignmentTool />
                </CardContent>
                <BottomToolBar 
                        authenticated={authenticated}
                        allowUpload={allowUpload}
                        handleDrop={this.handleDrop}
                        showActionOne={showActionOne}
                        saveDraft={this.saveDraft}
                        nameActionOne={nameActionOne}
                        hidePublish={hidePublish}
                        publishDocument={this.publishDocument}
                        actionName={actionName}
                        saving={saving}
                    />
            </Card>
        );
    }
}
export default compose(withStyles(styles), withWidth())(DefaultEditor);