import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { cyan} from "@material-ui/core/colors";


let theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    // primary: {
    //   // light: will be calculated from palette.primary.main,
    //   main: '#1F5586',
    //   light: '#386892',
    //   dark: "#12416B"
    //   // dark: will be calculated from palette.primary.main,
    //   // contrastText: will be calculated to contrast with palette.primary.main
    // },
    secondary: {
      main: "#F24141"
    },
    background: {
      default: "#101010",
      paper: "#282828"
    }
  },
});

theme = responsiveFontSizes(theme);


export default theme;
