import React from 'react';
import { withStyles, Snackbar, IconButton} from '@material-ui/core';


import {Close} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';


const styles = theme => ({
  close: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  root: {
    color: red[500],
  }
});

class SimpleSnackbar extends React.Component {
  state = {
    open: false,
    message: null,
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes, message, close } = this.props;
    let open = (message) ? true : false;
    return (
        <Snackbar
          className={classes.root}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={6e3}
          onClose={this.handleRequestClose}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={close}
            >
              <Close />
            </IconButton>,
          ]}
        />
    );
  }
}


export default withStyles(styles)(SimpleSnackbar);