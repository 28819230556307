import Eleven, {ElevenAPI} from "../Clients/Eleven.js";
import {SetUser} from "../Clients/GoogleAnalytics";
import config from "../Config/Config.js";

export const REQUEST_AUTH = "REQUEST_AUTH";
export const RESPONSE_AUTH = "RESPONSE_AUTH";
export const REQUEST_LINKING = "REQUEST_LINKING";
export const RESPONSE_LINKING = "RESPONSE_LINKING";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const SHOW_REGISTRATION = "SHOW_REGISTRATION";
export const SHOW_LINKING = "SHOW_LINKING";
export const FAIL_AUTH = "FAIL_AUTH";
export const FAIL_LINK = "FAIL_LINK";
export const LOGOUT = "LOGOUT";


function requestAuth(){
    return {
        type: REQUEST_AUTH,
        authenticated: false,
    }
}

function successAuth(res){

    var data = res.data.attributes;

    var acts = [];

    var linked = false;

    var profile = null;

    if(res.included && res.included.length > 0){
        var accounts = res.included;
        for(var i = 0; i < accounts.length; i++){
            var act = accounts[i];

            var accountData = {
                id: act.id,
                accountId: act.attributes['account-id'],
                name: act.attributes['name']
            }

            acts.push(accountData);
            linked = true;
        }


    }

    if(acts.length > 0){
        profile  =  {
            id: acts[0].id, 
            accountId: acts[0].accountId,
            name: acts[0].name
        }
    }

    SetUser(data.id);

    return {
        type: RESPONSE_AUTH,
        authenticated: true,
        errorAuth: false,
        loginHash: data.loginHash,
        user: data,
        receivedAt: Date.now(),
        showLogin: false, 
        accounts: acts,
        profile: profile,
        linked
    }
}

function failAuth(msg){
    return {
        type: FAIL_AUTH,
        authenticated: false,
        errorAuth: msg,
        receivedAt: Date.now()
    }
}

function logoutAuth(){
    return {
        type: LOGOUT
    }
}

function requestLinking(){
    return {
        type: REQUEST_LINKING,
        linking: true,
    }
}

function linkingSent(link){
    return {
        type: RESPONSE_LINKING,
        linking: false,
        link: link, 
        errorAuth: false
    }
}

function failLink(err){
    return {
        type: FAIL_LINK,
        linking: false,
        link: "0",
        errorAuth: err
    }
}

export const ProviderAuth = (userData) => (dispatch, getState) => {

}

export const ShowLogin = (showLogin, message) => {
    return {
        type: SHOW_LOGIN,
        showLogin: showLogin,
        errorAuth: message
    }
}

export const ShowRegistration = (showRegistration) => {
    return {
        type: SHOW_REGISTRATION,
        showRegistration
    }
}

export const ShowLinking = (showLinking) => {
    return {
        type: SHOW_LINKING,
        showLinking
    }
}

export const Authenticate = ( userCredentials ) => (dispatch, getState) => {
    if(!userCredentials) return RefreshAuthentication(dispatch);
    
    dispatch( requestAuth() )

    return ElevenAPI.post(config.authUrl, {
        method: "POST",
        body: {
            ...userCredentials
        }
    })
    .then(
        response => {
            const {data} = response;
            localStorage.setItem("AUTH_TOKEN", data.attributes['auth-token']);
            dispatch(successAuth(response));
        }, 
        error => {
            localStorage.removeItem('AUTH_TOKEN');
            dispatch(failAuth(error.error || "Invalid Credentials"));
        }
    )
}


export const LinkAccount = ( data ) => (dispatch, getState) => {
    dispatch(requestLinking());

    return Eleven.post('/auth/linking', {
        method: "POST",
        body: {
            ...data
        }
    })
    .then(
        response => {
            const {data} = response;
            dispatch(linkingSent(data.attributes))
        }, 
        error => {
            dispatch(failLink(error.error || "INVALID USERNAME PROVIDED"));
        }
    )
}

function RefreshAuthentication (dispatch) {
    let refreshToken = localStorage.getItem("refreshToken");
    
    if(!refreshToken){
        return;
    }
    
    dispatch( requestAuth() )
    
    return Eleven.post('/auth/refresh', {
        method: "POST",
        body: {
            refresh: refreshToken
        }
    })
    .then(
        res => {
            const {data} = res;
            dispatch(successAuth(res))
        },
        err => {
            dispatch(failAuth("SESSION TIMED OUT"));
            localStorage.removeItem('refreshToken');            
        }
    )
}

export const Logout = () => dispatch => {
    localStorage.removeItem("refreshToken");
    dispatch(logoutAuth());
}