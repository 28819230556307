import { CALL_API } from "../Middleware/Api";
import { 
    convertToRaw,
} from 'draft-js';

export const SET_CATEGORY = "SET_CATEGORY";

export const CategorySelected = (category) => {
    return {
        type: SET_CATEGORY,
        category: category
    }
}

export const GetCategories = (link) => (dispatch, getState) => {
    const {data} = getState();
    if(data.meta['/categories']){
        return; 
    }
    return dispatch({
        [CALL_API]: {
            endpoint: '/categories'
        }
    })
}

export const GetCategoryItems = (link) => {  
    return {
        [CALL_API]: {
            endpoint: link
        }
    }
}

export const GetCategoryItem = (id, category) => (dispatch, getState) => {
    const { data } = getState();    
    let endpoint = `/categories/${category}/category_elements/${id}`;
    if(data.meta[endpoint]){
        return;
    }
    return dispatch({
        [CALL_API]: {
            endpoint: `/categories/${category}/category_elements/${id}`
        }
    })
}

export const SelectCategory = category => {
    return {
        type: SET_CATEGORY,
        category
    }
}

export const CreateCategoryItem = (item, cat) => (dispatch, getState) => {
    const {Editor} = getState();
    const contentState = Editor.editorState.getCurrentContent();    
    const description = contentState.getPlainText();  
    let content = convertToRaw(contentState);
    const {category, categoryType} = cat
    let action = dispatch({
        [CALL_API]: {
            endpoint: `/categories/${category}/${categoryType}`,
            options: {
                method: "POST",
                body: JSON.stringify({
                    item: {
                        ...item,
                        content: JSON.stringify(content),
                        description
                    }
                })
            }
        }
    })
    action.then(
        result => dispatch({type: 'RESET_EDITOR_STATE'})
    )
    return action;
}