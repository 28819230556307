import React from "react";
import {NavLink} from 'react-router-dom'

import { Drawer, Hidden,  List, ListItem, ListItemIcon, ListItemText,  withStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
const drawerWidth = 205;

const styles = theme => ({
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
        title: {
            textAlign: 'center',
            lineHeight: "48px",
            [theme.breakpoints.up('md')]: {
                lineHeight: "64px",
            }
        },
        flexGrow: {
            display: 'flex',
            flex: '1 1 auto',
            justifyContent: "center"
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.up('sm')]: {
                marginTop: "108.4px"
            }
        },
        navIconHide: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        link: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
        },
        active: {
            backgroundColor: `${theme.palette.primary.main} !important`
        },
        sideBar: {
            marginTop: "108px"
        },
        activeButton:{
            color: green
        },
        drawerHeader: theme.mixins.toolbar,
        fab: {
            position: 'absolute',
            bottom: theme.spacing(9),
            right: theme.spacing(2),
            zIndex: 1000
        }
})


// /community/Issues/reports/24

class FriendList extends React.Component{
    activeRoute(pathName){
        if(pathName === this.props.location.pathname){
            return "selected"
        }
    }
    componentDidMount(){
        this.props.getFriends();
    }
    componentDidUpdate(){
        
    }
    render(){
        const {open, toggleMenu, friends} = this.props;
        const classes = this.props.classes;


        const ForwardNavLink = React.forwardRef((props, ref) => {
            return (
                <NavLink {...props} innerRef={ref} activeClassName={classes.active} />
            )
        })
        
        const SideNavList = friends.map(item => {
            return (
                <ListItem key={item.id} button component={ForwardNavLink} to={`/eleven/${item.id}`} >
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary={item.userName} />
                </ListItem>
            )
        })

        const DrawerList = () => {
            return (
                <div className={classes.sideNavInner}>            
                    <List className={classes.list}>
                        <ListItem key="search" button component={ForwardNavLink} to={`/search`} >
                            <ListItemIcon>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText primary="User Search" />
                        </ListItem>
                        {SideNavList}
                    </List>
                </div>     
            )
        }


        

        return (
            <>

                <Hidden smUp implementation="css">
                    <Drawer
                        type="temporary"
                        anchor="right"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        onClose={toggleMenu}
                        open={open}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}                        
                    >
                        <DrawerList /> 
                    </Drawer>

                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        variant="persistent"
                        anchor="right"
                        className={classes.sideBar}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        open
                    >
                        <DrawerList /> 
                    </Drawer>
                </Hidden>
            </>
        )
    }
}

export default withStyles(styles)(FriendList);