import React from "react";
import {connect} from "react-redux";

import {ThumbUp, ThumbDown} from '@material-ui/icons';
import { withStyles, IconButton, Typography } from '@material-ui/core';
import { CALL_API, API_ROOT } from "../../Middleware/Api";

const styles = theme => ({
    root: {
        display: "flex",
        color: theme.palette.text.secondary,
    },
    button: {

    },
    icon: {
        height: 24,
        width: 24
    },
    voteCount: {
        lineHeight: "48px",
        display: 'flex-align',
        textAlign: "center"
    }
})

class Vote extends React.Component{
    render(){
        const {classes, count, myVote, castVote, voted, authenticated} = this.props;
        return (
            <div className={classes.root}>
                <IconButton
                    disabled={!authenticated} 
                    onClick={ () => { castVote(myVote, voted, 1) } } 
                    color={
                        (myVote.value === 1) ? 'primary' : 'inherit'
                    }
                >
                        <ThumbUp  />
                </IconButton>
                <Typography className={classes.voteCount}>{count}</Typography>                
                <IconButton 
                    disabled={!authenticated} 
                    onClick={ () => { castVote(myVote,voted, -1) } }
                    color={
                        (myVote.value === -1) ? 'secondary' : 'inherit'
                    }
                >
                    <ThumbDown />
                </IconButton>
            </div>            
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    let count = 0;
    let myVote = {value: 0};
    let voted = false;

    count = ownProps.voteSum;
    
    console.log(ownProps)
    return {
        authenticated: state.Auth.authenticated,
        count,
        myVote,
        voted
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        castVote: (myVote, voted, newVote) => {
            if (voted && myVote.value !== newVote) {
                dispatch({
                    [CALL_API]: {
                        endpoint: `${API_ROOT}/${ownProps.voteLink}/${myVote.id}`,
                        options: {
                            method: "PATCH",
                            body: JSON.stringify({vote: {value:newVote} })
                        }
                    }
                })
            } else if(!voted){
                dispatch( {
                    [CALL_API]: {
                        endpoint: `${API_ROOT}/${ownProps.voteLink}`,
                        options: {
                            method: "POST",
                            body: JSON.stringify({vote: {value:newVote} })
                        }
                    }
                })
            } else if(voted && myVote.value === newVote){
                dispatch({
                    [CALL_API]: {
                        endpoint: `${API_ROOT}/${ownProps.voteLink}/${myVote.id}`,
                        options: {
                            method: "PATCH",
                            body: JSON.stringify({vote: {value:newVote} })
                        }
                    }
                })
            }
        }
    }
}

// const mergeProps = (state, {castVote, removeVote}, ownProps) => ({
//     ...state, 
//     ...ownProps,
//     vote: (value) => {
//         const {voteable_id, voteable_type} = ownProps;
//         castVote({voteable_id, voteable_type, value})
//     }
// })

const VoteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Vote));

export default VoteContainer;