import { CALL_API } from "../Middleware/Api";
import { 
    convertToRaw,
} from 'draft-js';

export const CreateAnnouncement = (announcement) => (dispatch, getState) => {
    const {title} = announcement;
    const {Editor} = getState();
    const contentState = Editor.editorState.getCurrentContent();    
    const description = contentState.getPlainText();  
    let draft = convertToRaw(contentState);    
    return dispatch({
        [CALL_API]: {
            endpoint: `/news`,
            options: {
                method: "POST",
                body: JSON.stringify({
                    announcement: {
                        title,
                        draft: JSON.stringify(draft),
                        description
                    }
                })
            }
        }
    })
}

export const UpdateAnnouncement = (announcement, publish) => (dispatch, getState) => {
    const {title, id} = announcement;
    const {Editor} = getState();
    const contentState = Editor.editorState.getCurrentContent();    
    const description = contentState.getPlainText();  
    let draft = convertToRaw(contentState);  
    
    let updatedAnnouncement = {
        title,
        draft: JSON.stringify(draft),
        description
    }
    if(publish){
        updatedAnnouncement.content = JSON.stringify(draft);
        updatedAnnouncement.published = true;
    }
    else {
        updatedAnnouncement.content = "";
        updatedAnnouncement.published = false;
    }
    return dispatch({
        [CALL_API]: {
            endpoint: `/news/${id}`,
            options: {
                method: "PATCH",
                body: JSON.stringify({
                    announcement: updatedAnnouncement
                })
            }
        }
    })
}

export const GetAnnouncement = (id) => {
    return {
        [CALL_API]: {
            endpoint: `/news/${id}`
        }
    }    
}

export const GetAnnouncements = () => {
    return {
        [CALL_API]: {
            endpoint: '/news'
        }
    }
}