import {connect} from 'react-redux';
import Editor from "../Components/Post/Form";
import Store from "../Store/index.js"

import { compose, lifecycle } from "recompose";

const clearForm = lifecycle({
    componentWillUnmount(){
        Store.dispatch({type: "RESET_EDITOR_STATE"})
    }
})

const mapStateToProps = (state, ownProps) => {
    const {Auth, Editor} = state;
    return {
        authenticated: Auth.authenticated,
        editorState: Editor.editorState
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onUpdate: (editorState) => {
            dispatch({
                type: "UPDATE_EDITOR_STATE",
                editorState
            })
        }
    }
}

let enhance = connect(
    mapStateToProps,
    mapDispatchToProps
);

const FormContainer = compose(enhance, clearForm)(Editor)

export default FormContainer; 