import React from "react";
import {
    Card,
    Paper,
    Grid,
    withStyles, 
    ListItem,
    ListItemText, 
    ListItemButton
} from '@material-ui/core';


import {Link} from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Button} from '@material-ui/core';
import {Eleven} from "../../Clients/Http";

import _ from "lodash";

const styles = theme => ({
    root: {
        height: "100%",
    },
    subheader: {
        padding: theme.spacing(2)
    },
    container: {
        maxHeight: "calc(100% - 56px)"
    },
    cardContainer: {
        height: "100%",
        padding: theme.spacing()
    },
    divContainer: {
        height: "100%"
    },
    card: {
        height: "100%"
    },
    action: {
        height: "48px",
        marginTop: theme.spacing()
    },
    tableRow: {
        backgroundColor: "#333"
    }
  });



  const initialState = {
    accounts: [],
    loading: false,
    first: 0,
    last: 0, 
    total: 0
  };

class UserSearchPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
      }


    componentDidMount(){
        Eleven.get('/leaderboards?start=0')
                .then((res) => {
                    var first, last, total, accounts;

                    accounts = res.data.scores;

                    if(accounts.length > 0){
                        first = accounts[0]["index"];
                        last = accounts[accounts.length - 1]["index"];
                    }
                    total = res.data.totalCount;
                    
                    this.setState({
                        loading: false,
                        first, last, total, accounts
                    })
                })
                .catch((err) => {
                    console.log(err);
                })
    }
    getNext(){
        var next = this.state.last + 1;

        if(next > this.state.totalCount){
            return;
        }
        Eleven.get(`/leaderboards?start=${next}`)
                .then((res) => { 
                    var last, accounts;
                    if(res.data.scores.length > 0){
                        last = res.data.scores[res.data.scores.length - 1].index;
                    }

                    var acts = this.state.accounts.concat(res.data.scores)
                    .sort((d1, d2) => d1.rank - d2.rank );
                    
                    var accounts = _.uniq(acts)

                    this.setState({
                        loading: false,
                        last, accounts
                    })
                })
                .catch((err) => {
                    console.log(err);
                })
    }

    render(){

        const {classes, next} = this.props;
        const {accounts, loading} = this.state;

        const matchList = accounts.map(a => (
            <TableRow key={a.rank} className={classes.tableRow}>
                <TableCell component="td" scope="row">
                    <Button component={Link} to={`/eleven/${encodeURIComponent(a.username)}`}>
                        {a.username}
                    </Button> 
                </TableCell>
                <TableCell align="right">{a.countryCode}</TableCell>
                <TableCell align="right">{a.rank}</TableCell>
                <TableCell align="right">{a.score}</TableCell>
            </TableRow>
        ))
        return (
            <Grid item xs={12}  className={classes.root}>
                <Grid container justify="center"  className={classes.card}>
                    <Grid item xs={12} md={8} className={classes.cardContainer}>
                        <Card variant="outlined" className={classes.divContainer} >
                            <TableContainer component={Paper} className={classes.container}>
                                <Table className={classes.table} stickyHeader  aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>USERNAME</TableCell>
                                        <TableCell align="right">FLAG</TableCell>
                                        <TableCell align="right">RANK</TableCell>
                                        <TableCell align="right">ELO</TableCell>
                                    </TableRow>
                                    </TableHead>
                                <TableBody>
                                    {matchList}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            {(!loading && accounts.length > 0) &&
                                <Button className={classes.action} variant="contained" color="secondary" onClick={() => this.getNext(next)} fullWidth={true}> Get Next </Button>

                            }
                        </Card >
                    </Grid>
                </Grid>
            </Grid>

            

        )
    }
}

export default withStyles(styles)(UserSearchPage);