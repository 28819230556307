import React from "react";
import {
    Grid,
    withStyles
} from '@material-ui/core';
import {Button} from '@material-ui/core';
import UserSearch from "../Containers/UserSearch";
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2), 
        position: 'relative', 
        '&:after': {
            content: '""',
            height: '105px',
            width: "100vw",
            display: "block"
        }
    },
    subheader: {
        padding: theme.spacing(2)
    }
  });

  const initialState = {
      matches: [],
      users: [],
  }

class UserMatchupPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            matches: [],
            users: [],
        }
      }

    onUserClick = (u) => {
        var users = this.state.users;
        
        if(users.length > 1){
            this.props.history.push(`/matchup/${users[0].id}/${users[1].id}`);
            return;
        }

        for(var i =0; i< users.length; i++){
            if(users[i].id == u.id){
                return;
            }

        }
        users.push(u);

        if(users.length == 2){
            this.props.history.push(`/matchup/${users[0].id}/${users[1].id}`)
            return;
        }

        this.setState({users: users});
    }

    removeChoice = (id) => {
        var newList = [];
        var users = this.state.users;

        for(var i =0; i< users.length; i++){
            if(users[i].id != id){
                newList.push(users[i]);
            }

        }
        this.setState({users: newList});

    }

    render(){

        const {classes} = this.props;

        const {users} = this.state;



        const selectedUsers = users.map((u) => (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                key={u.id}
                className={classes.button}
                startIcon={<DeleteIcon />} 
                onClick={ () => this.removeChoice(u.id) }  
            
            >
                {u.userName}
            </Button> 
        ))

        return (
            <Grid item xs={12} className={classes.root}>
                {selectedUsers}
                {this.state.users.length < 2 &&
                    <UserSearch onUserClick={this.onUserClick} skipPadding={true} />
                }
            </Grid>
        )
    }
}

export default withStyles(styles)(UserMatchupPage);