import { CALL_API } from "../Middleware/Api";

import { ShowLogin } from "./auth.js"

export const CreateLink = (link) => (dispatch, getState) => {
    const {data} = getState();

    const {description, title, destination} = link;

    return dispatch({
        [CALL_API]: {
            endpoint: "/links",
            options: {
                method: "POST",
                body: JSON.stringify({
                    link: {
                        description: description,
                        title,
                        destination,
                        public: link.public
                    }
                })
            }
        }
    })
}

export const GetLink = (id) => (dispatch, getState) => {

    const {Auth} = getState();

    if(!Auth.authenticated){
        dispatch(ShowLogin());
    }

    return dispatch({
        [CALL_API]: {
            endpoint: `/links/${id}`,
        }
    })

}
