import {FULLSCREEN_TOGGLE} from "../Actions/globalActions"

const InitialState = {
    fullScreen: false
}

export default (state=InitialState, action) => {
    switch (action.type){
        case FULLSCREEN_TOGGLE:
            return {
                ...state,
                fullScreen: action.fullscreen
            }
        default:
            return state;
    }
}