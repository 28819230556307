import React from "react";
import { withStyles, Grid } from '@material-ui/core';
import Profile from "./Profile";


const TeamMembers = [
    {
        name: "Hamzeh Alsalhi",
        role: "Developer",
        linkedIn: "https://www.linkedin.com/in/hamzehalsalhi?authType=NAME_SEARCH&authToken=WAgx&locale=en_US&trk=tyah&trkInfo=clickedVertical%3Amynetwork%2CclickedEntityId%3A159631576%2CauthType%3ANAME_SEARCH%2Cidx%3A1-1-1%2CtarId%3A1475013770333%2Ctas%3Aham",
        email: "hamzeh@forfunlabs.com",
        image: "https://s3-us-west-2.amazonaws.com/elevenvrmedia/uploads/team/hamzeh.png",
        description: "Hamzeh works as a software developer at a young startup, he is excited for the future of VR and enjoys making video games as a hobby"
    },
    {
        name: "Roman Rekhler",
        role: "Developer",
        email: "roman@forfunlabs.com",
        image: "https://s3-us-west-2.amazonaws.com/elevenvrmedia/uploads/team/roman.png",
        description: "Who am I. I am physics, I am magnets, I am the Aurora Borealius. Ok maybe not the last one cause it's too cool. I studied Engineering and Physics at Cooper Union, and have been a coder my whole life. I have played tennis, racquetball, and ping pong for many years (and that's just counting racquet sports). I wanted to try my hand at VR coding and started working on a ping pong game. Many months later, here we are."
    },
    {
        name: "Oscar Vazquez",
        role: "Developer",
        email: "oscar@forfunlabs.com",
        linkedIn: "https://www.linkedin.com/in/oscar-vazquez-1baaa980",
        image: "https://s3-us-west-2.amazonaws.com/elevenvrmedia/uploads/team/oscar.png",
        description: "Oscar currently works as an instructor at the Coding Dojo. Currently working on curriculum for a VR course, figured the best way to come up with material is to get his hands dirty."
    }
]

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      height: "100%",
      background: theme.palette.background.default,
      alignItems: "center",
      [theme.breakpoints.down('md')]: {
          overflowY: "scroll",
      }      
    }
  });

class AboutPage extends React.Component 
{
    render() {
        const classes = this.props.classes;

        const team = TeamMembers.map(admin => <Profile admin={admin} key={admin.name} />)
        
        return (
            <Grid className={classes.container}>
                {team}
            </Grid>
        )
    }
}



export default withStyles(styles)(AboutPage);