import {connect} from "react-redux";
import { CALL_API, API_ROOT } from "../../Middleware/Api";

import FriendList from "../Components/FriendList";
import build from 'redux-object';

//100
//933
const mapStateToProps = (state, ownProps) => {
    const {profile} = ownProps;

    const {meta} = state.data;
    const link = `${API_ROOT}/accounts/${profile.id}/friends`

    if(meta[link]){
        let friends = (meta[link].data || []).map(m => build(state.data, 'userAccounts', m.id))
        return {
            friends: friends, 
            loading: meta[link].loading,
            open: state.SideBar.open
        }
    }

    return {friends: [], loading: true, open: state.SideBar.open};
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFriends: () => {
            const {profile} = ownProps;
            dispatch( {
                [CALL_API]: {
                    endpoint: `${API_ROOT}/accounts/${profile.id}/friends`
                }
            })
        },
        getNext: (link) => {
            dispatch( {
                [CALL_API]: {
                    endpoint: link
                }
            })
        },
        toggleMenu: () => {
            dispatch( {type: "TOGGLE"} )
        }
    }
}


const FriendPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendList);

export default FriendPage;