import React from "react";
import Editor from "./Post/Editor.js";
import AddComment from "../Containers/AddComment";
import CommentList from "../Community/Containers/CommentList";
import { withStyles, Divider, Grid, Typography, Container } from '@material-ui/core';
import Title from "./Title";
import Avatar, {UserInfo} from "./User/Avatar";
import Moment from "moment"
let styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: 0
    },
    card: {
        flexGrow: 1,        
        margin: 0,
        minWidth: '60%',
        boxSizing: 'border-box',
        padding: 0
    },
    subheader: {
        color: theme.palette.text.primary,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    avatar: {
        height: 32,
        width: 32,
        margin: 8
    }

})

class Article extends React.Component{

    componentDidMount(){
        if(!this.props.article && !this.props.loading){
            this.props.getArticle(this.props.match.params.id);
        }
    }
    render(){
        const {article, loading, actions, classes} = this.props
        return (
            <Container fixed>
                    {(!loading && article) &&
                        <Grid container justify="center" direction="column" className={classes.card}>
                            <Grid item xs={12}  style={{ backgroundImage: `url(${article.headerUrl})`, minHeight: "240px", maxWidth: "100%" }}>
                                <Title 
                                    title={article.title}
                                />
                                <Grid container align="center">
                                <Avatar
                                    user={article.author} 
                                    avatar={classes.avatar}
                                />
                                <UserInfo user={article.author} timestamp={article.updatedAt} />
                            </Grid>
                            </Grid>

                            <Grid container align="center">
                                <Editor
                                    content={article.content}
                                    readOnly={true}
                                />   
                            </Grid>                      
                        <Grid item xs={12}>
                            {actions &&
                                <Grid container align="center" justify="space-between" className={classes.actions}>
                                    {actions(article)}
                                </Grid>
                            }                                
                        </Grid>
                        <Divider />                            
                        <Grid item xs={12}>
                            <AddComment 
                                commentLink={article.links.comments} 
                            />
                            <CommentList
                                commentLink={article.links.comments}
                            />
                        </Grid>
                    </Grid>
                }
            </Container>
        )
    }
}

export default withStyles(styles)(Article);
