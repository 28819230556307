export const FULLSCREEN_TOGGLE = "FULLSCREEN_TOGGLE";
export const MATCH_HISTORY_TYPE = "MATCH_HISTORY_TYPE";

export const FullscreenToggled = (fs) => {
    return {
        type: FULLSCREEN_TOGGLE,
        fullscreen: fs
    }
}

export const MatchHistoryToggled = (fs) => {
    return {
        type: FULLSCREEN_TOGGLE,
        matchType: fs
    }
}