	// static int i=0;
	// public static int Unset=						0;
	// public static int PrivateChat=				1;
	// public static int PublicChat=					2;
	// public static int Challenge=					3;
	// public static int ChallengeResponse=			4;
	// public static int StartMatchRequest=			5;
	// public static int JoinRoomRequest=			6;
	// public static int StartQuickMatchRequest=		7;
	// public static int JoinQuickRoomRequest=		8;
	// public static int MatchEvent=					9;
	// public static int GeoSet=						10;
	// public static int UserPunchInfo=				11;
	// public static int UserLeftTheRoom=			12;
	// public static int BoltBridgeRequest=			13;
	// public static int UserUpdateEvent=			14;
	// public static int Event=						15;
	// public static int FriendRequest=				16;
	// public static int FriendRequestAccepted=		17;
	// public static int FriendRequestRejected=		18;
	// public static int PlayerBlock=				19;
	// public static int FriendUpdate=				20;
	// public static int UnfriendedEvent=			21;
	// public static int FrendRequestCancellation=   22;
	// public static int CancelledFriendRequst=   	23;
	// public static int PendingFriendRequests=   	24;

export const PRIVATE_CHAT               = "1";
export const PUBLIC_CHAT                = "34";

// export const CHALLENGE_RESPONSE 
// export const START_MATCH_REQUEST
// export const JOIN_ROOM_REQUEST

// export const FRIEND_REQUEST
// export const FRIEND_REQUEST_ACCEPTED
// export const FRIEND_REQUEST_REJECTED
// export const PENDING_FRIEND_REQUEST