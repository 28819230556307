import React from "react";
import {connect} from "react-redux";
import {GetAnnouncement, UpdateAnnouncement} from "../../Actions/newsActions";
import AnnouncementForm from "./Components/announcementForm.js";
import build from 'redux-object';
import {withRouter} from "react-router";
import { CALL_API, API_ROOT } from "../../Middleware/Api";

class UpdateForm extends React.Component{
    componentDidMount(){
        const {id} = this.props.params;
        this.props.getAnnouncement(id)
            .then(res => {
                const rawContent = res.response.announcements[id].attributes.draft;
                this.props.setEditorState(rawContent);
            })
    }
    render(){
        if(this.props.loading){
            return (<h1>Loading</h1>)
        } else {
            return (
                <AnnouncementForm {...this.props} />
            )
        }
    }
}

const announceProps = (state, ownProps, announcement, loading, saving, actionName, nameActionOne) => (
    {
        announcement:  announcement,
        loading: loading,
        saving: saving,
        createEmpty: false,
        actionName: actionName,
        showActionOne: true,
        nameActionOne: nameActionOne,
        params: ownProps.match.params
    }
)

const mapStateToProps = (state, ownProps) => {
    const {meta} = state.data;
    let {id} = ownProps.match.params;

    let link = `${API_ROOT}/news/${id}`;

    if(meta[link]){

        let announcement;

        if(meta[link].loading){
            announcement = null
        }
        else {
            announcement = build(state.data, 'announcements', id);

            var actionName = (announcement.published) ? "Set Unpublished" : "Publish Announcement";

            var actionOne = (announcement.published) ? "Save Changes" : "Save Draft";
    
            return announceProps(state, ownProps, announcement, meta[link].loading, meta[link].saving, actionName, actionOne);
        
        }
    }

    return announceProps(state, ownProps, null, true, false, "Publish Announcement", "Save Draft");
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAnnouncement: (id) => {
            return dispatch( GetAnnouncement(id) )
        },
        saveAnnouncement: (announcement, publish) => {
           dispatch( UpdateAnnouncement(announcement, publish) );
        },
        setEditorState: (rawContent) => {
            dispatch({
                type: "SET_EDITOR_STATE",
                rawContent
            })
        }
    }
}


const changeAnnouncement = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateForm));

export default changeAnnouncement;