import {CALL_WS} from "../Middleware/Ws";
export const USERMESSAGE = "userMessage";
export const GETMESSAGE  = "userMessage.get";
export const PUBLICMESSAGE = "publicMessage";

export const CreateMessage = (message) => {
    return {
        [CALL_WS]: {
            callName: USERMESSAGE,
            type: "messages",
            message: {
                message
            }
        }
    }
}

export const GetMessages = () => {
    return {
        [CALL_WS]: {
            callName: GETMESSAGE,
            type: "messages",
            message: {
                global:"true"
            }
        }
    }
}


export function publicMessage(msg) {
    return {
        data: {
            type: "messages",
            id: msg.mid,
            attributes: {
                body: msg.body,
                createdAt: new Date(parseInt(msg.timeStamp, 10)),
            },
            relationships: {
                author: {
                    data: {
                        id: msg.sourceId,
                        type: "sockets"
                    }
                }
            }
        }
    }
}

// public vs private profile 

// If user 1 clicks on user 2 link 
//     I make a request for all of user 2 information {callName: "userList", uid: "2"}
//         I think we should get 
//             {
//                 ... // info we already have
//                 relationshipStatus: //friends, pending, etc..


//             }

