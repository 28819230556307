import React from "react";
import {
    Typography,
    Card,
    CardContent, 
    Paper,
    Grid,
    withStyles, IconButton
} from '@material-ui/core';
import {Link} from "react-router-dom";
import AccountCircle from '@material-ui/icons/AccountCircle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Button} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Eleven} from "../../Clients/Http";
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';


const codeTypeList = [
    {
        code: 0,
        label: "No Reason",
        description: "Most likely just wants to block the other user"
    },
    {
        code: 2,
        label: "Innapropriate Name",
        description: "Username is innapropriate"
    },
    {
        code: 3,
        label: "Name_Attacking",
        description: "?"
    },
    {
        code: 100,
        label: "Game_Serve_Rushing",
        description: "User is rushing his serve in an attempt to catch other user off guard"
    },
    {
        code: 101,
        label: "Game_Time_Wasting",
        description: "User is intentionally wasting time between during his serve in an attempt to frustrate other user"
    },
    {
        code: 102,
        label: "Game_Dislike_Style",
        description: "No idea"
    },
    {
        code: 103,
        label: "Game_Cheating",
        description: "User is cheating?"
    },
    {
        code: 104,
        label: "Game_Hitting_To_Where_I_Cant_Reach",
        description: "?"
    },
    {
        code: 105,
        label: "Game_Too_Spinny",
        description: "Opponent is using too many spins?"
    },
    {
        code: 106,
        label: "Game_Too_Fast",
        description: "?"
    },
    {
        code: 107,
        label: "Game_Fake_Serves",
        description: "?"
    },
    {
        code: 108,
        label: "Game_Excessive_Serve_Air_Swings",
        description: "?"
    },
    {
        code: 109,
        label: "Game_Abusing_Ball_Reset",
        description: "?"
    },
    {
        code: 110,
        label: "Game_Sandbagging",
        description: "?"
    },
    {
        code: 200,
        label: "Voice_Harassment",
        description: "?"
    },
    {
        code: 201,
        label: "Voice_Rude",
        description: "?"
    },
    {
        code: 202,
        label: "Voice_Racist",
        description: "?"
    },
    {
        code: 203,
        label: "Voice_Loud",
        description: "?"
    },
    {
        code: 204,
        label: "Voice_Threats",
        description: "?"
    },
    {
        code: 205,
        label: "Voice_Bullying",
        description: "?"
    },
    {
        code: 206,
        label: "Voice_Distracting",
        description: "?"
    },
    {
        code: 207,
        label: "Voice_Shit_Talking",
        description: "?"
    },
    {
        code: 208,
        label: "Voice_Loud_Breathing",
        description: "?"
    },
    {
        code: 209,
        label: "Voice_Cursing",
        description: "?"
    },
    {
        code: 300,
        label: "Body_Throat_Cutting_Motion",
        description: "?"
    },
    {
        code: 301,
        label: "Body_Sexual_Miming",
        description: "?"
    },
    {
        code: 302,
        label: "Body_Getting_Too_Close",
        description: "?"
    },
    {
        code: 303,
        label: "Body_Virtual_Harassment",
        description: "?"
    },
    {
        code: 304,
        label: "Body_Cursing",
        description: "?"
    },
    {
        code: 400,
        label: "Misc_Other",
        description: "?"
    },
    {
        code: 401,
        label: "Misc_Too_Many_Requests",
        description: "?"
    },
    {
        code: 402,
        label: "Misc_Throwing_Balls_At_Me",
        description: "?"
    },
    {
        code: 403,
        label: "Misc_Table_Height_Abuse",
        description: "?"
    },
    {
        code: 404,
        label: "Misc_No_Reason",
        description: "?"
    }

]

const styles = theme => ({
    root: {
        flexGrow: 1,
        '&:after': {
            content: '""',
            height: '105px',
            width: "100%",
        },
        padding: theme.spacing(2), 
        position: 'relative'
    },
    subheader: {
        padding: theme.spacing(2)
    },
    lost: {
        color: theme.palette.error.main, 
        minWidth: "80px"
    },
    won: {
        color: theme.palette.success.main, 
        minWidth: "80px"
    },
    elo: {
        minWidth: "80px"
    }, 
    formControl: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
        flexDirection: 'row'
      }
  });
  const initialState = {
    code: '',
    reported_id: '',
    reporter_id: ''
  };

class ReportsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
      }

    componentDidUpdate(prevProps){
        if(prevProps.linkPath != this.props.linkPath && this.props.loading != true){
            this.props.getReports()
        }
    }

    componentDidMount(){
        this.props.getReports()
    }

    handleReportCode = (event, reportCodes) => {
        console.log(event.target.value);
        this.setState({code: event.target.value})
        //this.props.getReportsByCode(event.target.value);
    }  
    handleReporterClick = (reporter_id) => {
        this.setState({reporter_id: reporter_id})

    }

    handleReportedClick = (reported_id) => {
        this.setState({reported_id: reported_id})

    }

    handleChange = (event) => {
        console.log("HANDLE CHANGE");
        this.setState({[event.target.name]: event.target.value})
    };

    filterResults = () => {
        this.props.filterResults(this.state.reporter_id, this.state.reported_id, this.state.code);
    }

    clearResults = () => {
        this.setState(initialState);
    }

    render(){

        const {reportCodes, code, reported_id, reporter_id} = this.state;

        const {reports, loading, classes, next, getNext, getReportsMinMax} = this.props;

        const reportList = reports.map(a => (
            <TableRow key={a.id} className={classes.tableRow}>
                <TableCell component="td" scope="row" align="left">
                    <IconButton
                        component={Link} 
                        to={`/eleven/${a.reported.id}`}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Button onClick={() => this.handleReportedClick(a.reported.id)}>
                        {a.reported.userName}
                    </Button> 
                </TableCell>
                <TableCell component="td" scope="row" align="left">
                    <IconButton
                        component={Link} 
                        to={`/eleven/${a.reporter.id}`}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Button onClick={() => this.handleReporterClick(a.reporter.id)}>
                        {a.reporter.userName}
                    </Button> 
                </TableCell>
                <TableCell align="center">{a.reportText}</TableCell>
                <TableCell align="right">{a.createdAt}</TableCell>
                <TableCell align="right">{a.reportCode}</TableCell>
            </TableRow>
        ))
        
            console.log(code);

        const opts = codeTypeList.map(a => (
                <option  value={a.code} aria-label="bold">
                    {a.code}: {a.label}
                </option >
            ))

        return (
            <Grid item xs={12}  className={classes.root}>
                <Grid container justify="center"  className={classes.card}>
                    <Grid item xs={12} md={12} className={classes.cardContainer}>

                        <Card variant="outlined" className={classes.divContainer} >
                            <Toolbar>
                                    <FormControl  variant="filled" className={classes.formControl}>
                                        <TextField id="outlined-reported" name='reported_id' label="reported" variant="outlined" value={reported_id} onChange={this.handleChange}  />
                                        <TextField id="outlined-reporter" name='reporter_id' label="reporter" variant="outlined" value={reporter_id} onChange={this.handleChange}  />
                                        <Select
                                            labelId="report-code-type-label"
                                            id="report-code-type"
                                            value={code}
                                            onChange={this.handleReportCode}
                                            variant="outlined"
                                            label="Code"
                                            native 
                                        >
                                            <option value="">
                                                None
                                            </option >
                                            {opts}
                                        </Select>
                                        <Button variant="outlined" onClick={this.filterResults}>Filter</Button>
                                        <Button variant="outlined" onClick={this.clearResults}>Clear</Button>
                                    </FormControl >
                                </Toolbar>
                            <TableContainer component={Paper} className={classes.container}>

                                <Table className={classes.table}  aria-label="simple table">
                                    
                                    <TableHead>
                                        <TableRow>

                                            </TableRow>
                                        <TableRow>
                                            <TableCell align="left">Reported</TableCell>
                                            <TableCell align="left">Reporter</TableCell>
                                            <TableCell align="center">Report Text</TableCell>
                                            <TableCell align="right">Created At</TableCell>
                                            <TableCell align="right">Code</TableCell>
                                        </TableRow>
                                    </TableHead>
                                <TableBody>
                                    {reportList}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            {(!loading && reports.length > 0) &&
                                <Button className={classes.action} variant="contained" color="secondary" onClick={() => getNext(next)} fullWidth={true}> Get Next </Button>

                            }
                        </Card >
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(ReportsPage);