import React from "react";
import {
    Grid,
    withStyles,
    Hidden
} from '@material-ui/core';


import {LineChart, YAxis, Tooltip, Line, ResponsiveContainer, ReferenceDot} from "recharts";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% + 32px)`
        },
        '&:after': {
            content: '""',
            height: '100px',
            width: "100%",
        },
        position: 'relative',
    },
    positive: {
        color: theme.palette.success.main,
        marginLeft: theme.spacing(2)
    },
    text: {
        color: theme.palette.text.primary
    },
    paper: {
        backgroundColor: theme.palette.background.paper
    }
  });

  const initialState = {
    accounts: [],
    current: 0,
    first: 0,
    last: 0, 
    total: 0
  };



class EloHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
      }

    componentDidMount(){
        this.props.getUserEloHistory()
    }

    render(){

        const {matches, classes} = this.props;

        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload != null) {
                return (
                    <div className="custom-tooltip">
                        <p className={classes.text}>{`CURRENT ELO : ${payload[0].value}`}</p>
                        <p className={classes.text}>{payload[0].payload.createdAt}</p>
                    </div>
                );
            }
          
            return null;
          };

        return (
            <Grid item xs={12} className={classes.root}>
                <Hidden smDown implementation="css">
                    <ResponsiveContainer width='100%' minHeight={360}>
                            <LineChart
                                data={matches}
                                margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                            >
                                <Tooltip content={<CustomTooltip/>}/>
                                <ReferenceDot r={0} />
                                <YAxis type="number" domain={['dataMin', 'dataMax']} hide={true} />
                                <Line 
                                    type="monotone" 
                                    dataKey="currentElo"
                                    stroke="#ff7300" 
                                    yAxisId={0} 
                                    animationDuration={300} 
                                    dot={false}
                                    strokeWidth={1.5} />
                            </LineChart>
                    </ResponsiveContainer>
                </Hidden>
                <Hidden mdUp implementation="css">
                    <ResponsiveContainer width='100%' minHeight={260}>
                            <LineChart
                                data={matches}
                                margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                            >
                                <Tooltip content={<CustomTooltip/>}/>
                                <ReferenceDot r={0} />
                                <YAxis type="number" domain={['dataMin', 'dataMax']} hide={true} />
                                <Line 
                                    type="monotone" 
                                    dataKey="currentElo"
                                    stroke="#ff7300" 
                                    yAxisId={0} 
                                    animationDuration={300} 
                                    dot={false}
                                    strokeWidth={1.5} />
                            </LineChart>
                    </ResponsiveContainer>
                </Hidden>
            </Grid>

            

        )
    }
}

export default withStyles(styles)(EloHistory);