import React, { Component } from 'react';

import MenuIcon from '@material-ui/icons/Menu';
import { withStyles, Menu, Hidden, withWidth, Drawer, IconButton } from '@material-ui/core';

import { green, grey } from '@material-ui/core/colors';
import NavItems from "./NavItems";
import compose from 'recompose/compose';
import {Link} from "react-router-dom";

const styles = theme => ({
  root: {
    height: '100%',
    justifyContent: "flex-end !important",
    display: "flex"
    
  },
  iconGrey: {
    fill: grey[500],
    '&:hover': {
      fill: green[200],
    },    
  },
  iconPositive: {
    fill: theme.palette.text.secondary,
    '&:hover': {
      fill: green[200],
    },    
  },
  button: {
  }

});

class ProfileIcon extends Component{
  state = {
    dropDown: false,
    anchorEl: null
  }
  toggleDropdown = (e) =>{
    this.setState({
      dropDown: !this.state.dropDown,
      anchorEl: e.currentTarget       
    })
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.state.dropDown && prevState.dropDown === false){
    //   setTimeout(()=> {
    //     window.addEventListener("click", this.hideDropDown)
    //   }, 5);
    // }
  }

  hideDropDown = (e) => {
    this.setState({
      dropDown: false,
      anchorEl: null 
    })
  }  

  render(){
    const {authenticated, classes, authenticating, currentUser, handleLogout, showLogin, showRegistration, showLinking, me, accounts, linked} = this.props;


    const ForwardNavLink = React.forwardRef((props, ref) => (
        <NavItems {...props} innerRef={ref} />
    ));
    
    const IconColor = (authenticated) ? "secondary" : "action";
    
    return (
      <div className={classes.root}>
          <IconButton 
              onClick={this.toggleDropdown}
              aria-label="add"         
              className={classes.button}   
              aria-owns={this.state.dropDown ? 'dropdown' : null}  
              aria-haspopup="true"
            >
              <MenuIcon 
                color={IconColor} 
              />
          </IconButton >
          <Drawer
              anchor="right"
              type="temporary"
              open={this.state.dropDown}
              onClose={this.toggleDropdown} 
            >
              <ForwardNavLink 
                authenticated={authenticated}
                authenticating={authenticating}
                handleLogout={handleLogout}
                currentUser={currentUser}
                showLogin={showLogin}  
                showRegistration={showRegistration}   
                showLinking={showLinking}
                me={me} 
                accounts={accounts}   
                hideDropdown={this.toggleDropdown}   
                linked={linked}                      
              />
            </Drawer>
      </div>  
    )
  }
}

export default compose(withStyles(styles), withWidth())(ProfileIcon);
