import React, { useState, useEffect} from "react";
import {  makeStyles, Grid, Typography, Button, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {Eleven} from "../../Clients/Http";
import moment from 'moment';
const useStyles = makeStyles(
    theme => ({
        root: {
            height: "100%",
        },
        subheader: {
            padding: theme.spacing(2)
        },
        container: {
            maxHeight: "calc(100% - 64px)"
        },
        divContainer: {
            height: "100%",
            padding: theme.spacing(2)
        },
        card: {
            padding: theme.spacing(2),
            height: "100%"
        },
        action: {
            height: "48px",
            marginTop: theme.spacing()
        },
        input: {
            backgroundColor: theme.palette.background.main
        },
        margin: {
            marginTop: theme.spacing()
        }
    }),
    { withTheme: true }
);

export const LinkCreatePage = ({createLink, history}) =>  {

    const [message, setMessage] = useState("");

    const [saving, setSaving] = useState(false);

    const classes = useStyles();

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const submitLink = () => {
        const link = {
            title: "Match Invitation",
            description: message,
            public: true,
            destination: "multiPlayer"
        }
        const data = {link: link};
        setSaving(true);
        Eleven.post(`/links`, data)
        .then((res) => { 
            console.log(res);
            setSaving(false);
            history.push('/links/' + res.data.data.id)
        })
        .catch((err) => {
            console.log(err);
            setSaving(false);
        })
    }

    return (
        <Grid item xs={12}  className={classes.root}>
            <Grid container justify="center"  className={classes.card}>
                <Grid item xs={12} md={8} className={classes.cardContainer}>
                        <Typography variant="h3" color="textPrimary">
                            Create Link
                        </Typography>
                        <FormControl fullWidth className={classes.margin} variant="filled">
                            <TextField
                                id="outlined-multiline-static"
                                label="Message"
                                multiline
                                rows={4}
                                placeholder="Send a message along with your link"
                                variant="outlined"
                                className={classes.input}
                                value={message}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <Button className={classes.action} disabled={saving} variant="contained" color="secondary" onClick={submitLink} fullWidth={true}> Create  </Button>

                </Grid>
            </Grid>
        </Grid>
    )
}

export const LinkShowPage = ({authenticated, link_id, currentProfile, location}) =>  {
    
    const [data, setData] = useState(null);

    const loadData = async() => {
        const res = await Eleven.get(`/links/${link_id}`)
        var d = res.data.data;
        const link = {
            id: d.id,
            ...d.attributes
        }
        console.log(link);
        setData(link);
    }
    
    useEffect(() => {
        
        loadData();

        return () => {};
    }, []);
    

    
    const classes = useStyles();

    const goTo = () => {
        window.location.href = data["destination-url"];
    }

    const copyLink = () => {
        const el = document.createElement('textarea');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return (
        <Grid item xs={12}  className={classes.root}>
            <Grid container justify="center"  className={classes.card}>
                <Grid item xs={12} md={8} className={classes.cardContainer}>
                    {data != null &&
                        <>
                            <Typography variant="h1" color="textPrimary">
                                {data.title}
                            </Typography>
                            {(data.author != null && data.author.id != 0)  && 
                                <Typography variant="h3" color="textPrimary" >
                                    {data.author.UserName}
                                </Typography>
                            }
                            <Typography variant="body1" color="textPrimary">
                                {data.description}
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                                Created At: { moment(data["created-at"]).calendar() }
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                                Expires At: { moment(data["created-at"]).add(1, "hours").calendar() }
                            </Typography>
                            
                            {currentProfile.id == data.author.id &&
                                <Button className={classes.action} variant="contained" color="primary" onClick={copyLink} fullWidth={true}> Copy Link URL  </Button>
                            }

                            <Button className={classes.action} variant="contained" color="secondary" onClick={goTo} fullWidth={true}> Go To Destination  </Button>

                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
