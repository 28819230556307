import React from 'react';
import AuthForm from "../AuthForm";
import { TextField, Button} from '@material-ui/core';

import Config from "../../../Config/Config";

import Eleven from "../../../Clients/Eleven";

class Login extends React.Component{
    state = {
        username: '',
        password: '',
        phone: '',
        showForgotPassword: false
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        const {username, password} = this.state;
        this.props.authenticateUser(
            {
                provider: "eleven",
                platform_uid: username,
                platform_name: "ELEVEN",
                platform_code: password, 
                platform_type: "WEB",
                username: username,
                password: password
              }
        )
    }

    handlePasswordReset = (e) => {
        const {phone} = this.state;
        Eleven.post(`/auth/passwordreset`, {
            body: {
                user: {
                    phone: phone
                }
            }
        })
        .then(res => {
            //this.props.history.push("/eleven")
            //this.props.showLogin();
        })
        .catch(err => {
            console.log(err);
        })
    }

    onSuccess = (provider, data) => {
        this.props.authenticateUser({
            provider: provider,
            ...data
        })   
    }

    showForgotPassword = () => {
        this.setState({
            showForgotPassword: true
        })
    }

    showLoginPage = () => {
            this.setState({
                showForgotPassword: false
            })
    }

    showOculusLogin = () => {
        
        this.props.oculusLogin()   

    }

    render(){
        const {authenticating, showLogin, closeLogin} = this.props;

        let actions = [];

        if(this.state.showForgotPassword){
            actions = [
                <Button
                    key="loginbutton"
                    variant="contained"
                    onClick={this.handlePasswordReset}
                    color="primary"
                    disabled={authenticating}
                >
                    Send Link
                </Button>,
                <Button                             
                    key="cancelbutton"
                    onClick={this.showLoginPage} color="primary"
                >
                    Cancel
                </Button>
                
            ]
        }
        else {
            actions = [
                <Button
                    key="loginbutton"
                    variant="contained"
                    onClick={this.handleSubmit}
                    color="primary"
                    disabled={authenticating}
                >
                    Log In
                </Button>,
                <Button                             
                    key="cancelbutton"
                    onClick={closeLogin} color="primary"
                >
                    Cancel
                </Button>
                
            ]
        }
        
        return (
                <AuthForm
                    logo="ELEVEN VR"
                    text="SIGN IN"
                    open={showLogin}
                    closeForm={closeLogin}
                    actions={actions}
                >

                    {!this.state.showForgotPassword && 
                        <>
                            <TextField
                                fullWidth
                                label="username"
                                name="username"
                                onInput={this.handleInput}
                                value={this.state.username}  
                                margin="normal"                 
                            />
                            <TextField
                                fullWidth
                                label="password"
                                name="password"
                                type="password"
                                onInput={this.handleInput}
                                value={this.state.password}  
                                margin="normal"                 
                            />
                            <Button      
                                key="oculus"
                                fullWidth={true}                       
                                variant="contained"
                                onClick={this.showOculusLogin} 
                                color="primary"
                                size="large"

                            >
                                OCULUS LOGIN
                            </Button>

                        </>
                    }
                    {this.state.showForgotPassword && 
                        <>
                            <TextField
                                fullWidth
                                label="PHONE NUMBER"
                                name="phone"
                                required
                                onInput={this.handleInput}
                                value={this.state.phone}  
                                margin="normal"                 
                            />
                        </>
                    }
            </AuthForm>
                    
        )
    }
}

export default Login;