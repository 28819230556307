const Logger = store => next => action => {

  if(process.env.NODE_ENV === 'development'){
    console.log('dispatching', action)
  }
  
  let result = next(action)
  
  if(process.env.NODE_ENV === "development"){
    console.log('next state', store.getState())
  }

  
  return result
}


export default Logger;