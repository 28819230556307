import React from "react"
import ButtonBase from '@material-ui/core/ButtonBase';
import { GridListTileBar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {MailOutline} from "@material-ui/icons"
import IconButton from "@material-ui/core/IconButton"
const styles = theme => ({
    image: {
        position: 'relative',
        height: 400,
        width: "300px !important",
        textAlign: "left",
        margin: theme.spacing.unit * 2,
        [theme.breakpoints.down('sm')]: {
          height: 350,
        },
        '&:hover': {
          zIndex: 1,
        },
        '&:hover $imageBackdrop': {
          opacity: 0.15,
        },    
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        zIndex: 100,
        cursor: 'default'
    },
    focused: {
        position: 'absolute',
        left: 100,
        right: 0,
        top: 100,
        bottom: 0,
        backgroundSize: "150px 100px",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "right",
        zIndex: 100,        
        transition: theme.transitions.create("background-size")
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    title: {
        zIndex: 200,
        background: "rgba(0,0,0, 0.8)",
        cursor: 'default'        
    }
  });

class Profile extends React.Component{

    // state= {
    //     focus: false
    // }

    // handleClick = (e) => (this.setState({focus: !this.state.focus}))
    handleLoad = (e) => (console.log("FINISHED LOADING"))
    render(){
        const {admin} = this.props;
        const classes = this.props.classes;


        // const imgClassName = classNames(
        //     {
        //         [classes.imageSrc]: !this.state.focus,
        //         [classes.focused]: this.state.focus
        //     }
        // )

        return (
            <ButtonBase focusRipple className={classes.image} >
                <div
                    className={classes.imageSrc}
                    style={{
                        backgroundImage: `url(${admin.image})`,
                    }}
                    onLoad={this.handleLoad}
                />
                <div className={classes.imageBackdrop} />                
                <GridListTileBar 
                    title={admin.name}
                    subtitle={admin.role}
                    actionIcon={
                        <IconButton component="a" href={`mailto:${admin.email}`}>
                            <MailOutline color="action" />
                        </IconButton>
                    }
                    className={classes.title}
                />
            </ButtonBase>
        )        
    }
}


export default withStyles(styles)(Profile);