import React from "react";

import {  NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        boxSizing: "border-box",
        '&:hover': {
            color: theme.palette.secondary.light,
        },
        display: 'block',
        position: 'relative'  
    },
    active: {
        color: theme.palette.secondary.light,    
    },
    underline: {
        '&:before': {
            backgroundColor: theme.palette.secondary.main,
            bottom: 0,
            content: '""',
            height: '4px',
            left: 0,
            position: 'absolute',
            right: 0,
            transition: theme.transitions.create('background-color', {
                duration: theme.transitions.duration.shorter,
                easing: theme.transitions.easing.ease,
            }),            
        }
    }
})

const ReduxLink = ({to, children, className, activeClass, classes, underline, textClass, ...other}) => {
    return (
        <NavLink
            className={classNames(
                classes.link,
                className
            )}
            to={to}
            activeClassName={classNames(
                classes.active,
                activeClass,
                (underline) ? classes.underline : ""
            )}
            {...other}
        >
            <span className={textClass}>
                {children}
            </span>
        </NavLink>
    )
}

export default withStyles(styles)(ReduxLink);