import React from "react";
import {
    Card,
    Paper,
    Grid,
    withStyles, 
    TextField
} from '@material-ui/core';
import {Link} from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Button} from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2), 
        position: 'relative'
    },
    noPadding: {
        padding: 0, 
        flexGrow: 0,
        position: "relative"
    },
    subheader: {
        padding: theme.spacing(2)
    }
  });


class UserSearchPage extends React.Component {

    
    componentWillUnmount(){
        this.props.clearSearch();
    }


    onUserClick(a){
        if(this.props.onClick != null){
            this.props.clearSearch();

            this.props.onClick(a);
        }
    }

    render(){

        const {accounts, loading, classes, next, getNext, skipPadding} = this.props;

        const userList = accounts.map(a => (
            <TableRow key={a.id}>
                <TableCell component="td" scope="row">
                    {this.props.onClick == null && 
                        <Button component={Link} to={`/eleven/${a.id}`}>
                            {a.userName}
                        </Button>                     
                    }
                    {this.props.onClick != null && 
                        <Button onClick={ () => this.onUserClick(a) }  >
                            {a.userName}
                        </Button> 
                    } 
                </TableCell>
                <TableCell align="right">{a.rank}</TableCell>
                <TableCell align="right">{a.elo}</TableCell>
            </TableRow>
        ))
        
        var rootClass = (skipPadding) ? classes.skipPadding : classes.root;

        return (
            <Grid item xs={12} className={rootClass}>
 
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            id="standard-full-width"
                            label="User Search"
                            placeholder="Username"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="off"
                            onChange={(e) => this.props.getUserAccounts(e.target.value)}
                        />
                        <Card variant="outlined">
                            <TableContainer component={Paper}>
                                <Table 
                                    className={classes.table} 
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>USERNAME</TableCell>
                                        <TableCell align="right">RANK</TableCell>
                                        <TableCell align="right">ELO</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userList}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card >
                    </Grid> 
                    {!loading && next !== null &&
                        <Grid item xs={12} md={8}>
                            <Button 
                                onClick={() => getNext(next)} fullWidth={true}
                            > 
                                Get Next 
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>

            

        )
    }
}

export default withStyles(styles)(UserSearchPage);