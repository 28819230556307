import React from "react";
import {
    Typography,
    Card,
    CardContent, 
    Paper,
    Grid,
    withStyles
} from '@material-ui/core';
import {Link} from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Button} from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        '&:after': {
            content: '""',
            height: '105px',
            width: "100%",
        },
        padding: theme.spacing(2), 
        position: 'relative'
    },
    subheader: {
        padding: theme.spacing(2)
    },
    lost: {
        color: theme.palette.error.main, 
        minWidth: "80px"
    },
    won: {
        color: theme.palette.success.main, 
        minWidth: "80px"
    },
    elo: {
        minWidth: "80px"
    }
  });


class MatchesPage extends React.Component {

    componentDidUpdate(prevProps){
        if(prevProps.linkPath != this.props.linkPath){
            this.props.getUserAccountMatches()
        }
    }


    componentDidMount(){
        this.props.getUserAccountMatches()
    }

    render(){

        const {matches, loading, classes, next, getNext} = this.props;

        const buildStats = (m) => {
            return [
                {
                    title: "HOME SCORE",
                    value: m.homeScore
                },
                {
                    title: "AWAY SCORE",
                    value: m.awayScore
                },
                {
                    title: "RANKED",
                    value: m.ranked
                }
            ]
        }

        const showElo = (m) => (m.state == 1 || m.state == 5 || m.state == 6) ;

        const roundRow = (r, team) => {
            if(team == 0){
                return (
                    <TableCell align="right" key={r.id}>{r.homeScore}</TableCell>
                )
            }
            else {
                return (
                    <TableCell align="right" key={r.id}>{r.awayScore}</TableCell>
                )
            }
        }

        const tableRow = (m, team) => {
            var teamScore = 0;
            var otherScore = 0;
            var teamELO = 0;
            var myTeam;
            var color = "primary";
            var starter = "+"

            var eloChange = m.eloChange;

            if(team == 0){
                teamScore = getHomeScore(m);
                otherScore = getAwayScore(m);
                teamELO = m.homeElo;
                myTeam = m.homeTeam;
                if(m.winner == 0){
                    color = classes.won;
                    starter = "+";
                    // teamELO += eloChange;
                }
                else {
                    color = classes.lost;
                    starter = "-";
                    eloChange = (teamELO > eloChange) ? eloChange : teamELO;
                    // teamELO -= eloChange;
                }

            }
            else {
                teamScore = getAwayScore(m);
                otherScore = getHomeScore(m);
                teamELO = m.awayElo;
                myTeam = m.awayTeam;
                if(m.winner == 1){
                    color = classes.won;
                    starter = "+"
                    // teamELO += eloChange;
                }
                else {
                    color = classes.lost;
                    starter = "-";
                    eloChange = (teamELO > eloChange) ? eloChange : teamELO;
                    // teamELO -= eloChange;
                }
            }

            return myTeam.map((p) => (
                <TableRow key={p.id}>
                    <TableCell component="td" scope="row">
                        <Button component={Link} to={`/eleven/${p.id}`}>
                            {p.userName}
                        </Button> 
                    </TableCell>
                    {m.ranked && showElo(m) &&
                        <TableCell align="right" className={color}>
                            {teamELO} ({starter}{eloChange})
                        </TableCell>
                    }

                    <TableCell align="right">{teamScore}</TableCell>
                        {m.Rounds.map((r, i) => (
                            roundRow(r, team) 
                        ) )
                    }
                </TableRow>
            ))


        }

        const getHomeScore = (m) => {
            var score = 0;
            for(var i = 0; i< m.Rounds.length; i++){
                var r = m.Rounds[i];

                if(r.homeScore > r.awayScore){
                    score++;
                }
            }
            return score;
        }

        const getAwayScore = (m) => {
            var score = 0;
            for(var i = 0; i< m.Rounds.length; i++){
                var r = m.Rounds[i];
                if(r.awayScore > r.homeScore){
                    score++;
                }
            }
            return score;
        }

        

        const matchList = matches.map((m) => (
            <Grid item xs={12} md={8} key={m.id}>
                <Card variant="outlined">

                    <CardContent >
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>USERNAME</TableCell>
                                    {m.ranked && showElo(m) &&
                                        <TableCell align="right" className={classes.elo}>ELO +-</TableCell>
                                    }
                                    <TableCell align="right">MATCH SCORE</TableCell>
                                    {m.rounds.map((r, i) => (
                                        <TableCell align="right" key={r.id}>ROUND {i + 1}</TableCell>
                                    ))}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRow(m, 0)}
                                    {tableRow(m, 1)}
                                </TableBody>
                            </Table>

                            </TableContainer>
                            <Button variant="contained" color="secondary" component={Link} style={{ display: "inline-block"}} to={`/matchup/${m.homeTeam[0].id}/${m.awayTeam[0].id}`}>
                                MATCHUP STATS
                            </Button>
                            <Typography type="subheading" className={classes.subheader} style={{ display: "inline-block"}}>
                                #{m.id} : {m.createdAt}
                            </Typography> 
                    </CardContent>
                </Card >
            </Grid>                     
        ))
        
        return (
            <Grid item xs={12} className={classes.root}>
                <Grid container justify="center" spacing={2}>
                    {matchList}
                    {!loading && next !== null &&

                        <Grid item xs={12} md={8}>
                            <Button onClick={() => getNext(next)} fullWidth={true}> Get Next </Button>
                        </Grid>

                    }
                    {loading && 
                        <Typography variant="h1" color="textPrimary" >LOADING</Typography>
                    }
                </Grid>
            </Grid>

            

        )
    }
}

export default withStyles(styles)(MatchesPage);