import merge from 'lodash/merge';
import _ from 'lodash'
import { API_DATA_REQUEST, API_DATA_SUCCESS, API_DATA_FAILURE } from '../Middleware/Api';
import { WS_DATA_REQUEST, WS_DATA_SUCCESS, WS_DATA_RECEIVE, WS_DATA_IGNORE } from '../Middleware/Ws';

const initialState = {
  meta: {},
};
let actionMethod;
export default function (state = initialState, action) {
  // console.log("GETTING TO ACTION STATE", action.response);


  switch (action.type) {
    case API_DATA_SUCCESS:

      let data = _(state.meta[action.endpoint].data)
        .keyBy('id')
        .merge(_.keyBy(action.response.meta[action.endpoint].data, 'id'))
        .values()
        .value();


      if(action.clear){
        data = []
      }

      switch(action.method) {
        case "GET":

          return merge(
            {},
            state,
            merge({}, action.response, { meta: { [action.endpoint]: { "loading": false, data } } }),
          );
        default: 
        
          actionMethod = actionType(action.method);

          return merge(
            {}, 
            state, 
            merge({}, action.response, { meta: { [action.endpoint]: { actionMethod: false, data }}})
          )
      }
    case API_DATA_REQUEST:
      console.log("API DATA REQUEST");
      console.log(action.clear);

      actionMethod = actionType(action.method)
      let merged = merge({}, state, { meta: { [action.endpoint]: { [actionMethod]: true, data: [] } } });
      if(action.clear && state.meta[action.endpoint]){

        state.meta[action.endpoint].data = [];

        return merge({}, state, { meta: { [action.endpoint]: { [actionMethod]: true } } });
      }
      else {
        return merge({}, state, { meta: { [action.endpoint]: { [actionMethod]: true } } });
      }
    case API_DATA_FAILURE: 
      actionMethod = actionType(action.method)
      return merge({}, state, { meta: { [action.endpoint]: {[actionMethod]: false, errors: action.error} } });
    case WS_DATA_RECEIVE: 
      return merge(
        {}, 
        state, 
        merge({}, action.response, {meta: { [action.endpoint]: {loading: false} } } )
      )
    case WS_DATA_REQUEST: 
      return merge({}, state, { meta: { [action.endpoint]: { loading: true } } });
    case WS_DATA_SUCCESS: 
      return merge(
        {},
        state, 
        merge({}, action.response, {meta: {[action.endpoint]: {loading: false} } } )
      )
    case WS_DATA_IGNORE:
      return state;
    default:
      return state;
  }
}

function actionType(method){
  switch(method) {
    case "GET":
      return "loading"
    case "POST":
      return "saving"
    case "PATCH": 
      return "updating"
    case "DELETE":
      return "deleting"
    default: 
      return "loading"
  }
}