import React, { Component } from 'react';
import Editor, {plugins, EmojiSuggestions, InlineToolbar, imagePlugin} from "./PluginHelper"
import { withStyles, withWidth, Hidden, Card, CardContent, Divider } from '@material-ui/core';
import compose from 'recompose/compose';

import EditorToolBar from "./Components/EditorToolbar";
import BottomToolBar from "./Components/BottomToolBar";
import classNames from 'classnames';

import "./Editor.css"
import { 
    EditorState,
    getDefaultKeyBinding, 
    KeyBindingUtil,
    RichUtils,
    convertToRaw,
    convertFromRaw,
    ContentState,
} from 'draft-js';


import UploadManager, {getPresignedUrl} from "./fileUpload"

const {hasCommandModifier} = KeyBindingUtil;


// function getBlockStyle(block) {
//     switch (block.getType()) {
//     case 'blockquote': return 'news-admin-blockquote';
//     default: return null;
//     }
// }

function myKeyBindingFn(e) {
    if (e.keyCode === 83 && hasCommandModifier(e)) {
        return 'myeditor-save';
    }
    return getDefaultKeyBinding(e);
}

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit,
  },
  card: {
    padding: theme.spacing.unit,
    paddingBottom: "0px",
    paddingTop: "0px",
    border: "1px solid rgba(239, 239, 239, .09)",
    boxShadow: "0 1px 4px rgba(239,239,239,.03)"
  },
  readOnly: {
    border: '0px',
    padding: '0px',
    boxShadow: 'none',
    backgroundColor: "transparent"
  },
  content: {
      ...theme.typography.body2,
      cursor: "text",
  },
  contentReadOnly: {
      ...theme.typography.body2,
      padding: '0px',
      color: theme.palette.text.secondary
  }
});

class DefaultEditor extends Component {

    constructor(props){
        super(props)
        this.uploadManager = null;
        let editorState;
        if(props.readOnly) {
            editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.content)));
        } else {
            editorState = (props.createEmpty) ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(JSON.parse(props.content)))
        }
        this.state = {
            editorState: editorState,
            uploadUrl: ''
        };        
    }
    onChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    refreshState = () => {
        let {editorState} = this.state;
        this.onChange(
            EditorState.push(editorState, ContentState.createFromText(''))
        );
    } 

    focus = () => {
        this.refs.editor.focus();
    };

    toggleBlockType = (blockType) => {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }
    handleKeyCommand = (command) => {
        if (command === 'myeditor-save') {
            this.saveDraft();
            return 'handled';
        }
        return 'not-handled';
    }

    saveDraft = () => {
        if(!this.props.saveDocument) return;
        const {editorState} = this.state;
        const contentState = editorState.getCurrentContent();    
        const plaintext = contentState.getPlainText();        
        this.props.saveDocument(convertToRaw(contentState), plaintext)        
    }

    publishDocument = () => {
        if(!this.props.publishDocument) return;
        const {editorState} = this.state;
        const contentState = editorState.getCurrentContent();
        const plaintext = contentState.getPlainText();      
        this.props.publishDocument(convertToRaw(contentState), plaintext, true)           
    }

    handleDrop = (selection, files) => {
        if(this.props.readOnly) return;
        if(this.uploadManager){
            this.uploadManager.uploadFile(files[0]);
        }
    }


    componentWillUpdate = (newProps) => {
        if(this.props.clearOnSaved && this.props.saving === true && newProps.saving === false && !this.props.readOnly){
            this.refreshState();
        }
    }

    render() {
        const {editorState} = this.state;
        // flags (booleans)

        //TODO: Take all these flags and seperate them into groups, top, full, content, bottom
        
        const {saving, actionName, allowUpload, hidePublish, authenticated, hideTools, showActionOne, nameActionOne, children} = this.props;
        const classes = this.props.classes;
        const editorPlaceHolderName= 'editor-hidePlaceholder';

        const {readOnly} = this.props;

        const editorClassName = classNames(
            'full-editor',
            {
                [classes.readOnly]: readOnly,
                [classes.card]: !readOnly,
                'read-only-content': readOnly
            },
        )


        // HIDE PLACEHOLDER
        var contentState = editorState.getCurrentContent();
        let hidePlaceHolder = false;

        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                hidePlaceHolder = true;
            }
        }

        const contentClassName = classNames(
         'editor',
         {
             [classes.contentReadOnly]: readOnly,
             [classes.content]: !readOnly,
             [editorPlaceHolderName]: hidePlaceHolder
         }   
        )

        return (
            <Card className={editorClassName} >
                {children &&
                    children
                }
                {(!readOnly && !hideTools) &&
                    <Hidden mdDown>
                        <EditorToolBar
                            editorState={editorState}
                            onToggle={this.toggleBlockType}
                        />
                        </Hidden>
                    }
                <CardContent className={contentClassName} onClick={this.focus}>
                    <Editor
                        editorState={this.state.editorState}
                        onChange={this.onChange}
                        plugins={plugins}
                        ref="editor"
                        handleDroppedFiles={this.handleDrop}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={myKeyBindingFn}
                        spellCheck={true}
                        readOnly={readOnly}
                        placeholder="Write Away..."
                    />
                    {!readOnly &&
                        <Hidden mdDown>
                            <div className="tool-sets">
                                <InlineToolbar />
                                <EmojiSuggestions />
                            </div>
                        </Hidden>
                    }
                </CardContent>
                
                {!readOnly &&
                    <Divider />
                }

                {(!readOnly) &&
                    <BottomToolBar 
                        authenticated={authenticated}
                        allowUpload={allowUpload}
                        handleDrop={this.handleDrop}
                        showActionOne={showActionOne}
                        saveDraft={this.saveDraft}
                        nameActionOne={nameActionOne}
                        hidePublish={hidePublish}
                        publishDocument={this.publishDocument}
                        actionName={actionName}
                        saving={saving}
                    />
                }
            </Card>
        );
    }
}
export default compose(withStyles(styles), withWidth())(DefaultEditor);