import {connect} from "react-redux";
import {GetAnnouncements} from "../../Actions/newsActions";
import IndexAnnounce from "./Components/IndexAnnounce.js";
import build from 'redux-object';
import { CALL_API, API_ROOT } from "../../Middleware/Api";

const mapStateToProps = (state, ownProps) => {
    const {meta} = state.data;

    let link = `${API_ROOT}/news`;

    if(meta[link]){
        const announcements = (meta[link].data || []).map(object => build(state.data, 'announcements', object.id))
        const loading = meta[link].loading;

        return { announcements, loading, admin: state.Auth.currentUser.role === "admin" }
    }
    return {
        data: meta[link],
        announcements: [], 
        admin: state.Auth.currentUser.role === "admin"
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAnnouncements: (post_id) => {
            dispatch( GetAnnouncements() )
        }
    }
}

const ListPost = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexAnnounce);

export default ListPost;