import {CALL_WS} from "../Middleware/Ws";
import { CALL_API } from "../Middleware/Api";

export const USERLIST = "userList";
export const PUBLICUSERINFO = "UserList.getPublicUserInfo"

export const CreateUser = (user) => {
    return {
        [CALL_API]: {
            endpoint: `/users`,
            options: {
                method: "POST",
                body: JSON.stringify({
                    user
                })
            }
        }
    }
}

export const GetUsers = () => {
    return {
        [CALL_WS]: {
            callName: 'userList',
            type: 'users',

        }
    }
}

export const GetScoreBoard = () => {
    return {
        [CALL_WS]: {
            callName: "userScoreBoard.list"
        }
    }
}

export const GetUser = (userName) => {
    return {
        [CALL_WS]: {
            callName: PUBLICUSERINFO,
            type: "messages",
            message: {
                otherUser:userName
            }
        }
    }
}
