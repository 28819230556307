import {connect} from "react-redux";
import {GetUserAccountMatches} from "../../Actions/userAccountActions"
import { CALL_API, API_ROOT } from "../../Middleware/Api";

import Matches from "../Components/MatchesPage";
import build from 'redux-object';
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
    const {linkPath} = ownProps;

    const {meta} = state.data;
    const link = `${API_ROOT}${linkPath}`

    if(meta[link]){
        let matches = (meta[link].data || []).map(m => {

            var match = build(state.data, 'matches', m.id);
            
            match.createdAt = moment(match.createdAt).calendar();

            match.Rounds = match.rounds.sort((d1, d2) => parseInt(d1.id) - parseInt(d2.id) );

            return match;
        }).sort((d1, d2) => parseInt(d2.id) - parseInt(d1.id) );
        let next = (meta[link].links) ? meta[link].links.next : null;
        return {
            matches: matches , 
            loading: meta[link].loading, 
            next
        }
    }

    return {matches: [], loading: true};
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserAccountMatches: () => {
            const {linkPath, skipRequest} = ownProps;
            if(skipRequest){
                return;
            }
            dispatch( {
                [CALL_API]: {
                    endpoint: `${API_ROOT}${linkPath}`
                }
            })
        },
        getNext: (link) => {
            dispatch( {
                [CALL_API]: {
                    endpoint: link
                }
            })
        }
    }
}


const MatchesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Matches);

export default MatchesPage;