import React from "react";
import {connect} from "react-redux";
import {GetAnnouncement} from "../Actions/newsActions";
import Article from "../Components/Article.js";
import build from "redux-object";
import Vote from "../Community/Containers/Vote";
import { CALL_API, API_ROOT } from "./../Middleware/Api";

const actions = (params) => (
    [
        <Vote key="votes" votes={params.votes} voteSum={params.voteSum}/>
    ]
)

class Announcement extends React.Component {
    render(){
        console.log(this.props)
        return (
            <Article actions={actions} {...this.props} />
        )
    }
} 

const mapStateToProps = (state, ownProps) => {

    let announcementId = ownProps.match.params.id;
    const {meta} = state.data;

    let link = `${API_ROOT}/news/${announcementId}`;

    if(meta[link]){
        if(!meta[link].loading) {
            let article = build(state.data, 'announcements', announcementId);
            article.content = (article.published) ? article.content : article.draft
            return {
                article, 
                loading: meta[link].loading
            }            
        }
    }

    return {
        loading: false
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getArticle: (post_id) => {
            dispatch( GetAnnouncement(post_id) )
        }
    }
}

const ShowPost = connect(
  mapStateToProps,
  mapDispatchToProps
)(Announcement);

export default ShowPost;