import React from "react";
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Rowing, AccountCircle,  Dashboard }from '@material-ui/icons';
import { MenuList, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
    link: {

    }
  }));


const ForwardNavLink = React.forwardRef((props, ref) => (
    <Link {...props} innerRef={ref} />
));


const NavItems = React.forwardRef(({authenticated, authenticating, handleLogout, currentUser,showLogin,linked, showLinking, me, accounts, hideDropdown}, ref) => {
    const {role} = currentUser;   
    
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
  
    const handleClick = () => {
      setOpen(!open);
    };

    return (
        <MenuList>
            {linked && 
                <ListItem button onClick={handleClick} >
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary={currentUser.profile.name} />
                </ListItem>                 
            }
                     
            {(!authenticated && !authenticating) &&
                <MenuItem onClick={showLogin}  >
                    <ListItemIcon>
                        <Rowing />
                    </ListItemIcon>
                    <ListItemText primary="Login" />
                </MenuItem>
            }
            {authenticated &&
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </MenuItem>  
            }   
        </MenuList>        
    )
})

export default NavItems;