import {connect} from "react-redux";
import {GetUserAccount} from "../../Actions/userAccountActions"
import UserPage from "../Components/UserPage";
import build from 'redux-object';
import { CALL_API, API_ROOT } from "../../Middleware/Api";

const mapStateToProps = (state, ownProps) => {
    const {id} = ownProps.match.params;

    const {linked, authenticated, currentProfile} = state.Auth;

    const {meta} = state.data;

    const link = `${API_ROOT}/accounts/${id}`

    if(meta[link]){
        let user, userId;
        
        if(!meta[link].loading && meta[link].data && meta[link].data.length > 0){
            userId = meta[link].data[0].id;
            
            user = build(state.data, 'userAccounts', userId);
        }

        return {user, loading: meta[link].loading, userId: id, linked, authenticated, currentProfile}
    }

    return {user: null, loading: true, userId: id, linked, authenticated, currentProfile};
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserAccount: () => {
            const {id} = ownProps.match.params;
            dispatch({
                [CALL_API]: {
                    endpoint: `${API_ROOT}/accounts/${id}`
                }
            })
        }
    }
}


const UserShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPage);

export default UserShow;