import React from "react";
import {connect} from 'react-redux';
import {Photo, VideoLabel} from "@material-ui/icons"
import {IconButton, TextField}  from '@material-ui/core';
import {GetUploadUrl, Upload, AddImage, AddVideo, SetImageUrl} from "../Actions/formActions"
import { compose, lifecycle, } from 'recompose';

import Store from "../Store/index.js"

const withPresignedUrl = lifecycle({
    componentDidMount(){
        Store.dispatch(GetUploadUrl());
    }
})

const ImageIcon = ({onChange, imgUrl, addImage, addVideo}) => {
    return (
        <> 
            <IconButton
                onClick={addImage}
            >
                <Photo />   
            </IconButton> 
            <IconButton
                onClick={addVideo}
            >
                <VideoLabel />   
            </IconButton> 
            <TextField 
                label="img url"
                onInput={onChange}
                id="imgUrl"
                value={imgUrl}
            />
        </>        
    )
}

const mapStateToProps = (state, ownProps) => {
    const {Auth, Editor} = state;
    return {
        authenticated: Auth.authenticated,
        editorState: Editor.editorState,
        imgUrl: Editor.imgUrl
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onUpdate: (editorState) => {
            dispatch({
                type: "UPDATE_EDITOR_STATE",
                editorState
            })
        },
        onChange: (e) => {
            console.log(e.target.value);
            dispatch(SetImageUrl(e.target.value))
        },
        addImage: () => {
            console.log("adding image")
            dispatch(AddImage())
        },
        addVideo: () => {
            console.log("adding image")
            dispatch(AddVideo())
        }
    }
}


let enhance = connect(
    mapStateToProps,
    mapDispatchToProps
);

const UploadImage = compose(
    enhance,
    withPresignedUrl
)(ImageIcon)


export default UploadImage; 