import React from 'react';

import {Route, Switch} from "react-router-dom";

import Wrapper from "./Components/Wrapper"
import Announcement from "./Containers/Announcement"
import AnnouncementList from "./Components/Announcements/AnnouncementList"
import CreateAnnouncement from "./Components/Announcements/createAnnouncement"

import Team from "./Components/Contact/Team";

const Home = () =>(
    <Wrapper>
        <CreateAnnouncement />
        <Switch>
            <Route exact path="/" component={AnnouncementList} />                          
            <Route path="/news/:id" component={Announcement} />
        </Switch>
    </Wrapper>
)

export default Home;