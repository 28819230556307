import ReactGA from 'react-ga';

const trackingId = "UA-176801487-1"; // Replace with your Google Analytics tracking ID

ReactGA.initialize(trackingId, {
    debug: false
});

const SetUser = (id) => {
    ReactGA.set({
        userId: id
    })
} 

const AddPageView = (path) => {
    ReactGA.pageview(path);
}

const AddModalView = (path) => {
    ReactGA.modalview(path);
}

export {SetUser, AddPageView, AddModalView}