import React from "react";
import Editor from "../../../Containers/FormContainer.js"
import { withStyles, TextField, Grid, Container } from '@material-ui/core';
import ImageUpload from "../../../Containers/ImageUpload";

let styles = theme => ({
    content: {
        width: "100%"
    },
    card: {
        margin: 0,
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(2)
    },
    subheader: {
        color: theme.palette.text.primary,
        marginTop: theme.spacing(1)
    },
    titleField: {
        ...theme.typography.headline
    }
})

class NewPost extends React.Component{
    constructor(props){
        super(props);
        const {title, thumbnailUrl, headerUrl} = props.announcement;
        this.state = {
            title: title,
            thumbnailUrl,
            headerUrl 
        }
    }

    handleChange = (name) => e => { 
        this.setState( { [name]: e.target.value} ) 
    }

    saveDraft = (content, description, publish) => {

        const {saveAnnouncement, match, announcement} = this.props;
        const {title} = this.state;
        const id = match.params.id;
        if(announcement.published){
            saveAnnouncement({id, title}, true);
        }
        else {
            saveAnnouncement({id, title}, false);
        }
    }

    togglePublished = (content, description, published) => {
        const {saveAnnouncement, match, announcement} = this.props;
        const {title, headerUrl, thumbnailUrl} = this.state;
        const id = match.params.id;
        if(announcement.published){
            saveAnnouncement({id, title}, false);
        }
        else {
            saveAnnouncement({id, title}, true);
        }
    }

    render(){
        const {loading, actionName, showActionOne, nameActionOne, saving, classes} = this.props;
        return (
            <Container fixed>
                {!loading && 
                    <div className={classes.content}>
                        <TextField
                            id="title"
                            label="Announcement Title"
                            className={classes.titleField}
                            value={this.state.title}
                            onChange={this.handleChange('title')}
                            margin="normal"
                            fullWidth
                            required
                        />   
                        <TextField
                            id="headerUrl"
                            label="Announcement Header Image Url"
                            className={classes.titleField}
                            value={this.state.headerUrl}
                            onChange={this.handleChange('headerUrl')}
                            margin="normal"
                            fullWidth
                            required
                        /> 
                        <TextField
                            id="thumbnailUrl"
                            label="Announcement Thumbnail Image Url"
                            className={classes.titleField}
                            value={this.state.thumbnailUrl}
                            onChange={this.handleChange('thumbnailUrl')}
                            margin="normal"
                            fullWidth
                            required
                        /> 
                        <Editor
                            saveDocument={this.saveDraft}
                            publishDocument={this.togglePublished}
                            saving={saving}
                            actionName={actionName}
                            allowUpload={true}
                            showActionOne={showActionOne}
                            nameActionOne={nameActionOne}
                            placeholder="I believe in you... This announcement is going to be amazing!"
                        />
                        <ImageUpload />
                    </div>
                }
            </Container>    
        )
    }
}

export default withStyles(styles)(NewPost)