import Eleven from "../Clients/Eleven.js";
// import moment from "moment";
import {imagePlugin, videoPlugin} from "../Components/Post/PluginHelper";

export const UPDATE_EDITOR_STATE = "UPDATE_EDITOR_STATE";
export const RESET_EDITOR_STATE = "RESET_EDITOR_STATE";
export const SET_EDITOR_STATE = "SET_EDITOR_STATE";
export const RES_UPLOAD_URL = "RES_UPLOAD_URL";
export const SET_IMAGE_URL = "SET_IMAGE_URL";

function resUpload(data){
    return {
        type: RES_UPLOAD_URL,
        upload: {
            url: data.url,
            fields: {...data.fields}
        }
    }
}

export const GetUploadUrl = ( ) => (dispatch, getState) => {
    const {Editor} = getState();
    if(!Editor.upload.url){
        //getPresignedUrl(dispatch);
    }
}

export const SetImageUrl = (imgUrl) => {
    return {
        type: SET_IMAGE_URL,
        imgUrl: imgUrl
    }
}

export const AddImage = () => (dispatch, getState) => {
    const {Editor} = getState();

    dispatch({
        type: UPDATE_EDITOR_STATE,
        editorState: imagePlugin.addImage(Editor.editorState, Editor.imgUrl)
    })
}

export const AddVideo = ( ) => (dispatch, getState) => {
    const {Editor} = getState();

    dispatch({
        type: UPDATE_EDITOR_STATE,
        editorState: videoPlugin.addVideo(Editor.editorState, {src: Editor.imgUrl})
    })

} 

export const Upload = (file) => (dispatch, getState) => {
    const {Editor} = getState();
    const {upload} = Editor;
    return new Promise((resolve, reject) => {
        var formData = new FormData();
        for(var key in upload.fields){
          formData.append(key, upload.fields[key])
        }
        formData.append('file', file);

        var xhr  = createCORSRequest("POST", upload.url);

        xhr.onreadystatechange = () => {
          console.log(xhr.readyState);  
          var xmlDoc = createXMLDoc(xhr.response);
          console.log(xmlDoc);

          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 201)
          {
            var xmlDoc = createXMLDoc(xhr.response);
            var url = xmlDoc.getElementsByTagName("Location")[0].childNodes[0].nodeValue;

            resolve(url);
          }
        };
        xhr.send(formData);
    })
}

function getPresignedUrl(dispatch){
    Eleven.get("/images/upload")
    .then(
        res => {
            dispatch(resUpload(res))
        },
        err => {
            console.log("ERROR UPLOADING");
        }
    )
}


function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    if ("withCredentials" in xhr) {
      xhr.open(method, url, true);
  
    } else {
      xhr = null;
    }
    return xhr;
  }
  
  function createXMLDoc(txt){
    var xmlDoc, parser;
    if (window.DOMParser)
    {
        parser = new DOMParser();
        xmlDoc = parser.parseFromString(txt, "text/xml");
    }
    else if(typeof window.ActiveXObject !== "undefined")// Internet Explorer
    {
        xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
        xmlDoc.async = false;
        xmlDoc.loadXML(txt);
    }
    return xmlDoc;
  }