import React from "react";
import Comment from "./Comment";
import { withStyles, Grid, Typography,  } from '@material-ui/core';


const styles = theme => ({
    root: {
        flexGrow: 1,
        '&:before': {
            content: '""',
            height: theme.spacing(2),
            width: "100vw",
            display: "block"
        },        
        '&:after': {
            content: '""',
            height: '50px',
            width: "100vw",
            display: "block"
        }
    },
    message: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
  });

class CommentList extends React.Component{

    componentDidMount(){
        if(!this.props.loaded){
            this.props.loadComments();
        }
    }

    render(){
        const {comments, classes, loading, nested} = this.props;
        console.log(comments);
        let commentList = comments.map((comment) => (
                <Grid item key={comment.id} xs={12}>
                    <Comment comment={comment}  />
                </Grid>
        ))
        return (
            <Grid item xs={12} className={classes.root}>
                <Grid container justify="center" spacing={6}>
                    {(!loading && commentList.length > 0) &&
                        commentList
                    }
                    {(!loading && commentList.length === 0) &&
                        <Typography variant="body2" className={classes.message} color="textPrimary">
                            No {!nested ? 'comments' : 'replies'}  yet
                        </Typography>
                    }
                    {loading &&
                        <Typography variant="body2" className={classes.message} color="textPrimary">
                            LOADING...
                        </Typography>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(CommentList);