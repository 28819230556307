import {connect} from "react-redux";
import {CreateLink, GetLink} from "../../Actions/linkActions";
import {LinkCreatePage, LinkShowPage} from "../Components/LinkCreatePage";
import build from 'redux-object';

const linkProps = (state, saving, history) => (
    {
        saving: saving,
        authenticated: state.Auth.authenticated,
        history        
    }
)

const mapStateToProps = (state, ownProps) => {
    const {meta} = state.data

    const path = "/links";

    if(meta[path]){
        return linkProps(state, meta[path].saving, ownProps.history)
    }
    return linkProps(state, false, ownProps.history);
} 


const mapStateToPropsGetLink = (state, ownProps) => {
    const {id} = ownProps.match.params;
    const {authenticated, currentProfile} = state.Auth;

    return {authenticated, link_id: id, currentProfile, location: ownProps.location}
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createLink: (link) => {
            return dispatch( CreateLink(link) )
        },
        getLink: () => {
            const {id} = ownProps.match.params;
            return dispatch( GetLink(id) )
        }
    }
}

const mapDispatchToPropsGet = (dispatch, ownProps) => {
    return {
        getLink: () => {
            const {id} = ownProps.match.params;
            return dispatch( GetLink(id) )
        }
    }
}

const NewLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkCreatePage);

export const ViewLink = connect(
    mapStateToPropsGetLink,
    mapDispatchToPropsGet
  )(LinkShowPage);

export default NewLink;