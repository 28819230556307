import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch, NavLink} from "react-router-dom";
import Wrapper from "../Components/Wrapper";
import UserShow from "./Containers/UserShow";
import LeaderboardPage from "./Components/LeaderboardPage";
import UserSearch from "./Containers/UserSearch";
import SubHeader from "../Components/SubHeader"
import Friends from "./Containers/Friends"
import Reports from "./Containers/Reports"
import Drill from "./Containers/Drill"
import LinkNew, {ViewLink} from "./Containers/LinkCreate"


import NewAnnouncement from "../Components/Announcements/createAnnouncement"
import UpdateAnnouncement from "../Components/Announcements/updateAnnouncement";

import Announcement from "../Containers/Announcement"
import AnnouncementList from "../Components/Announcements/AnnouncementList"

import UserMatchup from "./Components/UserMatchupPage";
import Matchup from "./Containers/UserMatchup";

import ForgotPassword from "../Components/Auth/ForgotPasswordForm";


import { Button, Hidden,  BottomNavigationAction , withStyles, BottomNavigation } from "@material-ui/core";
import { compose } from 'recompose';

import {AccountBox, Polymer, Dns, Search, LockOpen, People }from '@material-ui/icons';

import {ShowLogin, ShowLinking, Logout} from "../Actions/auth.js";
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';

const styles = theme => ({
  content: {
    width: '100%',
    height: "100%"
  },
  contentLinked: {
    height: "100%",
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${205}px)`
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  navigation: {
    transition: theme.transitions.create(['height', 'justify-content'], {
      duration: theme.transitions.duration.short,
    }),
    width: "100vw",
    position: "fixed",
    bottom: 0,
    height: 56,
    right: 0,
    backgroundColor: theme.palette.background.default,
    borderTop: `.8px solid rgb(56, 68, 77)`,
    display: 'flex',
    justifyContent: 'center',
    height: 56,
    backgroundColor: theme.palette.background.paper,
  },
  navigationBig: {
    transition: theme.transitions.create(['height', 'justify-content'], {
      duration: theme.transitions.duration.short,
    }),
    width: "100vw",
    position: "fixed",
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
    borderTop: `.8px solid rgb(56, 68, 77)`, 
    justifyContent: 'flex-start',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    height: 86
  },
  color: theme.palette.primary.main,
  link: {
    minWidth: " 0px",
    maxHeight: "56px"
  },
  subWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column'
  },
  subWrapperFull: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: 'column',
  },
  subAction: {
    marginTop: "4px"
  }
})


  class Eleven extends Component {

      constructor(props) {
        super(props);
        this.state = {path: props.location.pathname};
      }

      onBottomNavChange = (e, newValue) => {
        this.props.history.push(newValue);
        this.setState({path: newValue})
      }

      render(){

        const path = this.props.match.path;

        let links = [
          {
            id: "lbs",
            label: "Leaderboard",
            path: `/`
          },
          //{
           // id: 'news',
          //  label: "Game News", 
           // path: `/eleven/news`
          //},
          {
            id: 'search',
            label: "User Search", 
            path: `/search`
          }, 
          {
            id: 'matchup',
            label: "Match Up",
            path: `/matchup`
          },
          {
            id: "tournamentrankings",
            label: "Tournament Ranking",
            path: "/ranking"
          }
        ]
        const {classes, profile, linked, toggleMenu, location, authenticated, showLogin, showLinking, fullScreen, role} = this.props;

        if(linked){
          links.unshift({
            id: 'profile',
            label: profile.name,
            path: `/eleven/${profile.id}`
          })
        }
        if(authenticated && (role == "mod" || role == "admin" )){
          links.push({
            id: 'reports', 
            label: "User Reports",
            path: "/eleven/account/reports"
          })

          links.push({
            id: "newArticle",
            label: "New Article",
            path: "/eleven/news/new"
          })
        }

        var contentClass = (linked) ? classes.contentLinked : classes.content;

        const bottomClass = (fullScreen) ? classes.navigationBig : classes.navigation;

        const Bottom = () => {
          return (
            <Hidden smUp implementation="css">
              <BottomNavigation classes={ {root: bottomClass} } value={this.state.path} onChange={this.onBottomNavChange}>
                  {linked && 
                    <BottomNavigationAction className={classes.link} label="profile" value={`/eleven/${profile.id}`} icon={<AccountBox />}  />
                  }
                  <BottomNavigationAction className={classes.link} label="leaderboard" value="/" icon={<Dns />} />
                  <BottomNavigationAction className={classes.link} label="search" value="/search" to="/search"icon={<Search />} />
                  <BottomNavigationAction className={classes.link} label="matchup" value="/matchup" icon={<RestaurantMenuIcon />} />
                  {linked &&
                    <BottomNavigationAction className={classes.link} label="friends" onClick={toggleMenu} icon={<People />}  />
                  }
                  {(!authenticated) && 
                    <BottomNavigationAction className={classes.link} label="link" onClick={showLogin} icon={<LockOpen />}  />  
                  }
                </BottomNavigation>
            </Hidden>
          )
        }

        const Sub = () => {
          return (
            <Hidden xsDown>
              <SubHeader links={links} action={<SubHeaderAction />} />
            </Hidden>
          )
        }

        const SubHeaderAction = () => {

          const icon = (authenticated) ? <Polymer /> : <LockOpen />;
          
          if(linked){
            return (<></>)
          }

          return (
            <Button 
                color="secondary" 
                className={classes.subAction}
                variant="contained"
                startIcon={icon}
                onClick={
                    (authenticated) ?
                      showLinking
                    :
                      showLogin
                }>
                {(!authenticated) ? "Sign In" : "Link Account"}
            </Button>  
          )
        }
//`/eleven/account/${a['user-id-reported']}/reports`
          return (
            <Wrapper sub={<Sub />} bottom={<Bottom /> } justify="flex-start" >  
              {this.props.linked &&
                <Friends profile={this.props.profile} />
              }
              <div className={contentClass}>
                <Switch>  
                    <Route path={`/`} exact component={LeaderboardPage} />
                    <Route path="/eleven/news" exact component={AnnouncementList} />
                    <Route path="/eleven/news/:id" exact component={Announcement} />
                  

                    <Route path={`/search`} exact component={UserSearch} />  
                    <Route path={`/matchup`} exact component={UserMatchup} /> 
                    <Route path={`/matchup/:userId/:otherId`} exact component={Matchup} /> 
                    {((role == "mod" || role == "admin" )) && 
                      <>
                        <Route path="/eleven/account/reports" exact component={Reports} /> 
                        <Route path="/eleven/account/:id/reports" exact component={Reports} /> 
                        <Route path="/eleven/news/new" exact component={NewAnnouncement} />
                        <Route path="/eleven/news/:id/edit" component={UpdateAnnouncement} />
                      </>
                    }
                    <Route path="/drills/:id" exact component={Drill} />   
                    <Route path="/password/:token" exact component={ForgotPassword} />
                    <Route path="/links/new" component={LinkNew} />
                    <Route path="/links/:id" component={ViewLink} />
                    <Route path="/eleven/:id" component={UserShow} /> 
                </Switch>

              </div>
            </Wrapper>
          )
      }
  }
  
  const mapStateToProps = (state, ownProps) => {
    const {currentProfile, linked, authenticated, currentUser} = state.Auth;

    const {fullScreen} = state.Global;
    return {
      authenticated: authenticated, 
      linked: linked,
      profile: currentProfile,
      fullScreen,
      role: (currentUser) ? currentUser.role : ""
    };
} 


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      toggleMenu: () => {
          dispatch( {type: "TOGGLE"} )
      },
      showLogin: () => {
          dispatch(ShowLogin(true))
      },
      showLinking: () => {
          dispatch(ShowLinking(true))
      }
  }
}


const reduxLink = connect(
  mapStateToProps,
  mapDispatchToProps
);

const ElevenPage = compose(reduxLink, withStyles(styles))(Eleven);

export default ElevenPage;