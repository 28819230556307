import {SET_CATEGORY} from "../Actions/categoryAction"

const selected = {
    category: "",
    search: ""
}

const category = (state = selected, action) => {
    switch(action.type){
        case SET_CATEGORY:
            return {
                ...state, 
                category: action.category
            }
        case "USER_SEARCH":
            return {
                ...state, 
                search: action.search
            }
        default:
            return state
    }
}

export default category;