import Socket from "../Clients/WebSocket.js";
import Config from "../Config/Config.js";
import { MESSAGE_WS } from "../Middleware/Ws";

import SignalRClient from "../Clients/SignalRClient";

export const RESPONSE_AUTH = "RESPONSE_AUTH";
export const REQUEST_CONNECT = "REQUEST_CONNECT";
export const CONNECT = "CONNECT";
export const DISCONNECT = "DISCONNECT"
export const ME = "ME";


function requestConnect(){
    return {
        type: REQUEST_CONNECT,
        connecting: true,
        connected: false
    }
}

function connected(data){
    return {
        type: CONNECT,
        connecting: false,
        connected: true,
        data
    }
}

function me(data){
    return {
        type: ME,
        me: data
    }
}

function disconnected(){
    return {
        type: DISCONNECT,
        connected: false
    }
}

function generalMessage(msg){
    return {
        [MESSAGE_WS]: {
            msg
        }
    }
}



export const Connect = (loginHash, wsServer = Config.wsServer) => (dispatch) => {
    dispatch(requestConnect())
    wsServer += loginHash;
    Socket.setOnConnect( () => dispatch( connected() ) )
    Socket.setOnDisconnect( () => dispatch( disconnected() ) )
    Socket.setOnFirstMessage( (msg) => dispatch( me(msg) ) )
    Socket.setOnGeneralMessage( (msg) => dispatch(generalMessage(msg) ) )
    Socket.initialize(wsServer);
    return Socket;
}
