import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createSideToolbarPlugin from 'draft-js-side-toolbar-plugin'; // eslint-disable-line import/no-unresolved
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createVideoPlugin from 'draft-js-video-plugin/lib/createVideoPlugin';

import 'draft-js-emoji-plugin/lib/plugin.css';
import "draft-js-image-plugin/lib/plugin.css"
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import 'draft-js-side-toolbar-plugin/lib/plugin.css';
import "draft-js-alignment-plugin/lib/plugin.css"


const emojiPlugin = createEmojiPlugin();
const sideToolbarPlugin = createSideToolbarPlugin();
const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();
const resizeablePlugin = createResizeablePlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();
const alignmentPlugin = createAlignmentPlugin();

export const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
    focusPlugin.decorator,
    blockDndPlugin.decorator,
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,    
);
export const imagePlugin = createImagePlugin({ decorator });

export const videoPlugin = createVideoPlugin({ decorator });


export const { EmojiSuggestions } = emojiPlugin;
export const { InlineToolbar } = inlineToolbarPlugin;
export const { SideToolbar } = sideToolbarPlugin;
// const { AlignmentTool } = alignmentPlugin;
export const plugins = [
    emojiPlugin,
    focusPlugin,
    imagePlugin,
    videoPlugin,
    blockDndPlugin,
    resizeablePlugin,
    inlineToolbarPlugin,
    alignmentPlugin,
    sideToolbarPlugin
];

export default Editor;