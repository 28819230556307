import {
    Typography,
    Paper, 
    makeStyles
} from '@material-ui/core';
import React from "react";

const useStyle = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      textAlign: "center",
      flexDirection: "column",
      color: theme.palette.text.secondary,
    },
    stat: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    statName: {
        flex: 1,
        alignSelf: "flex-end"
    },
    statValue: {
        fontWeight: "1000",
        fontSize: "3rem"
    }
  }));

export default function Stats(props){

    const classes = useStyle();

    const {stats} = props;

    return (
        <>
            { stats.map((stat) => (
                <Paper elevation={3}  className={classes.paper} key={stat.title}>
                    <div className={classes.stat}>
                        <Typography variant="h3" color="textPrimary" className={classes.statValue} >{stat.value}</Typography>
                    </div>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary" >{stat.title}</Typography>
                    </div>
                </Paper>
            )) }
        </>
    )
}
