import {connect} from "react-redux";
import { CALL_API, API_ROOT } from "../../Middleware/Api";

import Reports from "../Components/ReportsPage";
import build from 'redux-object';
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
    const {id} = ownProps.match.params;

    const {meta} = state.data;
    let link = `${API_ROOT}/api/v1/user_reports`

    if(id !== undefined){
        link = `${API_ROOT}/api/v1/accounts/${id}/user_reports`
    }

    if(meta[link]){

        if(meta[link].loading && meta[link].clear){
            return {
                reports: [], 
                loading: meta[link].loading, 
                linkPath: link
            }
        }

        let reports = (meta[link].data || []).map(m => {
            var report = build(state.data, 'userReports', m.id)

            report.createdAt = moment(report.createdAt).calendar();

            return report;
        }).sort((d1, d2) => parseInt(d2.id) - parseInt(d1.id) );

        let next = (meta[link].links) ? meta[link].links.next : null;
        return {
            reports: reports, 
            loading: meta[link].loading, 
            linkPath: link,
            next
        }
    }

    return {reports: [], loading: true, linkPath: link};
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getReports: () => {
            const {id} = ownProps.match.params;
            if(id !== undefined){
                dispatch( {
                    [CALL_API]: {
                        endpoint: `${API_ROOT}/api/v1/accounts/${id}/user_reports`
                    }
                })
            }
            else {
                dispatch( {
                    [CALL_API]: {
                        endpoint: `${API_ROOT}/api/v1/user_reports`
                    }
                })
            }

        },
        getReportsByCode: (code) => {
            dispatch( {
                [CALL_API]: {
                    endpoint: `${API_ROOT}/api/v1/user_reports?code=${code}`, 
                    clear: true
                }
            })
        },
        filterResults: (reporter_id, reported_id, code) => {

            let base = `${API_ROOT}/api/v1/user_reports?`

            let notFirst = false;

            if(reported_id == '' && reporter_id == '' && code == ''){
                dispatch( {
                    [CALL_API]: {
                        endpoint: `${API_ROOT}/api/v1/user_reports`, 
                        clear: true
                    }
                })
                return;
            }

            if(reporter_id !== ''){
                base += `reporter_id=${reporter_id}`
                notFirst = true;
            }

            if(reported_id !== ''){
                if(notFirst){
                    base += '&'
                }
                base += `reported_id=${reported_id}`
            }

            if(code !== ''){
                if(notFirst){
                    base += '&'
                }
                base += `code=${code}`
            }

            console.log(base);

            dispatch( {
                [CALL_API]: {
                    endpoint: base, 
                    clear: true
                }
            })
        },
        getNext: (link) => {
            dispatch( {
                [CALL_API]: {
                    endpoint: link
                }
            })
        }
    }
}


const ReportsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);

export default ReportsPage;