import React from "react";
// import Editor from "../../Post/Editor";
import { withStyles, Button, Collapse, IconButton, Typography, Grid } from '@material-ui/core';
import {Editor, EditorState, convertFromRaw} from 'draft-js';
import { ExpandMoreSharp } from '@material-ui/icons';
import classnames from 'classnames';

import Avatar, {UserInfo} from "../User/Avatar";
import Vote from "../../Community/Containers/Vote";
import CommentList from "../../Community/Containers/CommentList";
import NewComment from "../../Containers/AddComment";

const styles = theme => ({
    root: {
        width: "100%",
        boxSizing: 'border-box',   
        '&:after': {
            content: '""',
            height: 1,
            display: "block",
            backgroundColor: theme.palette.background.paper
        }          
    },
    content: {
        width: "100%",
        boxSizing: 'border-box'
    },
    comment: {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        marginTop: theme.spacing.unit,
        width: "100%",
        boxSizing: 'border-box',
          
    },
    flexGrow: {
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'space-between',
    },   
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        height: 32,
        width: 32,
        margin: 8
    },
    expanded: {
        paddingLeft: "3%",
        marginTop: theme.spacing(1)
    },
    commentCount: {
        lineHeight: "48px",
        display: 'flex-align',
        textAlign: "center"
    }    
})

class Comment extends React.Component{
    constructor(props){
        super(props)
        const {comment} = props;
        this.state = {
            editorState:  EditorState.createWithContent(convertFromRaw(JSON.parse(comment.comment))),
            expanded: false,
            showForm: false,
        };
    }
    
    handleExpand = (e) => {
        this.setState({expanded: !this.state.expanded})
    }
    
    handleExpandForm = (e) => {
        let expanded = (!this.state.showForm) ? true : this.state.expanded
        this.setState({
            showForm: !this.state.showForm,
            expanded: expanded
        })
    }

    render(){
        const {comment, classes} = this.props;
        return (
            <div className={classes.root}>
                <Grid container align="center">
                    <Avatar
                        user={comment.user} 
                        avatar={classes.avatar}
                    />
                    <UserInfo user={comment.user} timestamp={comment.createdAt} />
                </Grid>
                <div className={classes.content}>
                    <div className={classes.comment}>
                        <Editor 
                            editorState={this.state.editorState}
                            readOnly={true}
                        />
                    </div>
                    <Grid container align="center" justify="space-between" className={classes.actions}>
                        <Vote 
                            voteLink={comment.links.votes}
                            votes={comment.votes}
                        />
                        <div className={classes.flexGrow}>
                            <Button onClick={this.handleExpandForm}>
                                REPLY 
                            </Button>

                            <Button 
                                onClick={this.handleExpand} 
                                endIcon={<ExpandMoreSharp 
                                                className={classnames(classes.expand, {
                                                [classes.expandOpen]: this.state.expanded,
                                            })}
                                        />}
                                >     
                                    {pluralReply(comment)}                                                  
                            </Button>
                        </div>
                    </Grid>
                    <Grid item className={classes.expanded}>
                        <Collapse in={this.state.showForm} timeout="auto" unmountOnExit>         
                            <Grid item xs={12}>
                                <NewComment
                                    commentLink={comment.links.comments}
                                />
                            </Grid>
                        </Collapse>                    
                        <Collapse in={this.state.expanded} timeout="auto" style={{ marginTop: 16 }} unmountOnExit>
                            <CommentList
                                nested={true}                            
                                commentLink={comment.links.comments}
                            />
                        </Collapse>
                    </Grid>
                </div>
            </div>
        )

    }
}

const pluralReply = (comment) => {
    // eslint-disable-next-line 
    var replyString = (comment.commentCount === 1) ? 'reply' : 'replies';
    var pluralString = `${comment.commentCount} ` + replyString;
    return pluralString
}

export default withStyles(styles)(Comment);