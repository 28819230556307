import React from "react";
import {
    Typography,
    Card,
    Grid,
    withStyles
} from '@material-ui/core';


const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2), 
        position: 'relative'
    },
    subheader: {
        padding: theme.spacing(2)
    }
  });

  const initialState = {
    accounts: [],
    loading: false,
    first: 0,
    last: 0, 
    total: 0
  };

class DrillCreator extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
      }


    componentDidMount(){
        this.props.getDrill();
    }

    render(){

        const {classes, drill, loading} = this.props;

        console.log(drill, loading)

        const drillType = (step) => {

            if(step.drillableType == "Launch"){
                return (
                    <>
                        <Typography variant="h3" color="textPrimary" className={classes.text}>
                            {step.drillable.name} {step.drillableType}
                        </Typography>
                        <Typography variant="body" color="textPrimary" className={classes.text}>
                            Payload {JSON.stringify(step.drillable.payload)}
                        </Typography>
                    </>
                )
            }
            else if(step.drillableType == "Serve"){
                return (
                    <>
                        <Typography variant="h3" color="textPrimary" className={classes.text}>
                            {step.drillable.name} {step.drillableType}
                        </Typography>
                        <Typography variant="body" color="textPrimary" className={classes.text}>
                            Payload {JSON.stringify(step.drillable.payload)}
                        </Typography>
                    </>
                )
            }
            else if(step.drillableType == "StepDelay"){
                return (
                    <>
                        <Typography variant="body" color="textPrimary" className={classes.text}>
                            Seconds Delay: {step.drillable.secondsDelay}
                        </Typography>

                    </>
                )
            }
            else if(step.drillableType == "StepRepeater"){
                return (
                    <>
                        <Typography variant="h5" color="textPrimary" className={classes.text} >
                            Steps To Repeat: {step.drillable.steps}
                        </Typography>
                        <Typography variant="h5" color="textPrimary" className={classes.text} >
                            Repeat Count: {step.drillable.count}
                        </Typography>
                    </>
                )
            }
            else {
                return (
                    <>
                        <Typography variant="h3" color="textPrimary" className={classes.text}>
                            {step.drillableType}
                        </Typography>
                        <Typography variant="h5" color="textPrimary" className={classes.text}>
                            Condition Type {step.drillable.condition}
                        </Typography>
                        <Typography variant="body" color="textPrimary" className={classes.text}>
                            Payload {JSON.stringify(step.drillable.payload)}
                        </Typography>
                    </>
                )
            }
        }

        const steps = (drill) => {

            return drill.steps.map(s => {
                return (
                    <Grid item xs={12} md={8}>
                        <Card variant="outlined" key={s.id}>
                            <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: "500"}}>

                                {drillType(s)}
                            </Typography>
                        </Card >
                    </Grid>
                )
            })

        }
        return (
            <Grid item xs={12} className={classes.root}>
                {!loading &&
                    <>
                    <Typography variant="h2" color="textPrimary" className={classes.text} style={{fontWeight: "500"}}>
                        {drill.name}
                    </Typography>
                    <Typography variant="body2" color="textPrimary" className={classes.text} style={{fontWeight: "500"}}>
                        {drill.description}
                    </Typography>
                    <Grid container justify="center" spacing={2}>
                            {steps(drill)}
                    </Grid>
                    </>
                }


            </Grid>

            

        )
    }
}

export default withStyles(styles)(DrillCreator);