// {article.title.split(" ").map(text => (<span className={classes.titleText}>{text}</span>) ) }
import React from "react";
import { withStyles, Typography } from '@material-ui/core';

let styles = theme => ({
    title: {
        lineHeight: "36px",
        letterSpacing: 0
    },
    titleText: {
        backgroundImage: `linear-gradient(-125deg, #FF2626 100%, #E20808 100%)`,
        color: theme.palette.text.primary,
        display: "inline-block",
        marginBottom: "0px",
        padding: [[theme.spacing(1), theme.spacing(2)]],
        whiteSpace: "nowrap",
        fontSize: "36px"
    },
})

const TitleSpan = ({text, className, fontSize}) => {
    return (
        <span className={className} style={{fontSize}}>{text}</span>        
    )
}

let resizeTimeout = null;
const titlePadding = 40;
const documentPadding = 64;
// TODO: CLEAN UP TITLE WIDTH
// TODO: GET ACCURATE WIDTH OF DOCUMENT INSTEAD OF ^^^
class Title extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            titleText: this.setTitle()
        }
    }

    componentDidMount(){
        window.addEventListener('resize', this.resizeHandler, false);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.resizeHandler, false);
    }

    componentWillReceiveProps(newState, newProps){
        if(this.props.title !== newProps.title){
            this.setState({
                titleText: this.setTitle()
            })
        }
    }

    resizeHandler = () => {
        if ( !resizeTimeout) {
            resizeTimeout = setTimeout(() => {
            resizeTimeout = null;
            this.setState({
                titleText: this.setTitle()
            })
            // 15fps
            }, 66);
        }      
    }
    
    setTitle = () => {
        const {classes, title} = this.props;
        let fontSize = 36;
        if(this.props.fontSize){
            fontSize = this.props.fontSize;
        }
        const characterWidth = fontSize / 2;
        let words = title.split(" ");
        let result = [];
        var clientWidth = document.documentElement.clientWidth - documentPadding;
        let line = [];
        for(var i = 0; i < words.length; i ++){
            line.push(words[i])
            let sentence = line.join(" ")
            let wordLength = (sentence.length * characterWidth) + titlePadding;
            if(wordLength >= clientWidth && line.length > 1){
                line.pop();
                result.push(
                    <TitleSpan 
                        key={i} 
                        className={classes.titleText}
                        text={line.join(" ")}
                        fontSize={fontSize}
                    />
                )
                line = [words[i]];
            } else if(wordLength > clientWidth && line.length === 1){
                result.push(
                    <TitleSpan 
                        key={i} 
                        className={classes.titleText}
                        text={sentence}
                        fontSize={fontSize}
                        
                    />
                )    
                line = [];
            }
        }
        result.push(
            <TitleSpan 
                key={"final"} 
                className={classes.titleText}
                text={line.join(" ")}
                fontSize={fontSize}
                
            />       
        )
        return result;
    }


    render(){
        const {classes} = this.props;

    
        return (
            <div  className={classes.title} >
                {this.state.titleText}
            </div>
        )
    }
}

export default withStyles(styles)(Title);

// linear-gradient(-125deg, #00f746 0%, #00f4b4 100%)