import normalize from 'json-api-normalizer';
// import Schemas from "./Schema";
import Socket from "../Clients/WebSocket.js";

export const WS_DATA_REQUEST = 'WS_DATA_REQUEST';
export const WS_DATA_SUCCESS = 'WS_DATA_SUCCESS';
export const WS_DATA_FAILURE = 'WS_DATA_FAILURE';
export const WS_DATA_IGNORE  = 'WS_DATA_IGNORE'; 
export const WS_DATA_RECEIVE = "WS_DATA_RECEIVE";

function sendMessage(message={}, next, action){
    return new Promise((resolve, reject) => {
        // delete message.type
        // console.log(message, "MESSAGE IN SEND MESSAGE")
        Socket.sendMessage(message, (err, result) => {
            if(err){
                return reject(err)
            }
            if(result.data){
                return resolve(Object.assign({}, normalize(result, {endpoint: message.callName} )) )
            } else {
                return resolve();
            }
        })
    })
}

export const MESSAGE_WS = Symbol('Message WS');
export const CALL_WS = Symbol('Call WS');

const WebSocketRequest = ({getState}) => next => action => {
    const callWS = action[CALL_WS];
    if(typeof callWS === 'undefined'){
        return next(action);
    }

    const {Auth} = getState();
    if(Auth.currentUser && !Auth.currentUser.linked){
        return;
    }

    let { callName, message } = callWS;

    if(!Socket){
        return;
    }

    if(typeof callName !== 'string'){
        throw new Error("callName must be a string you provided", callWS);
    }

    if(typeof message === 'undefined'){
        message = {};
    }

    const actionWith = (data) => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_WS];
        return finalAction;
    }

    next(actionWith({ type: WS_DATA_REQUEST, endpoint: callName }));

    message.callName = callName;
    
    return sendMessage(message, next, action)
        .then(
            response => {
                if(!response){
                    return next({type: WS_DATA_IGNORE})
                }
                next(actionWith({response, type: WS_DATA_SUCCESS, endpoint: callName}))
            },
            error => next(actionWith({ type: WS_DATA_FAILURE, error: error || "SOMETHING WENT WRONG"}))
        )
}


const WebSocketReceive = ({getState}) => next => action => {

    const messageWS = action[MESSAGE_WS];

    if(typeof messageWS === 'undefined'){
        return next(action);
    }
    const {Auth} = getState();
    if(Auth.currentUser && !Auth.currentUser.linked){
        return next(action);
    }   

    const {msg} = messageWS;
    const actionWith = (data) => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_WS];
        return finalAction;
    }
    let normalized = Object.assign({}, normalize(msg));

    return next(actionWith( { response: normalized, type: WS_DATA_RECEIVE } ) )

}

export default WebSocketRequest;

export { WebSocketReceive };


//THINGS NEEDED

/* UserList 
    In the case where he is not connected uid vs id doesn't matter because you shouldn't
    be receiving any data from websocket server

    How can I make sure that the id used in web server does not clash with id in 
    eleven hub server 




*/