import React from "react";
import {
    Typography,
    Grid,
    withStyles
} from '@material-ui/core';
import StatBox from "../../Components/StatBox.js";
import Matches from "../Containers/Matches";
const styles = theme => ({
    root: {
      flexGrow: 1,
       padding: 0
    },
    header: {
      padding: theme.spacing(2),
    },
    stat: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    statName: {
        flex: 1,
        alignSelf: "flex-end"
    },
    userData: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: "center",
        '& > *': {
          margin: theme.spacing(1),
          width: theme.spacing(14),
          height: theme.spacing(14),
        }
    },
    text: {
        color: theme.palette.text.primary
    },
    subheader: {
        color: theme.palette.text.secondary
    }
  });



class Matchup extends React.Component{
    constructor(props) {
        super(props);
        this.state = {ranked: false};
      }

      onCheckChange = (e) => {
        this.setState({
            ranked: e.target.checked
        })
    }

    componentDidMount(){
        this.props.getMatchup();
    }

    render() {

        const {matches, user, other, loading, classes, userWins, otherWins} = this.props;
        const {ranked} = this.state;

        let link = "";

        if(user != undefined){
            link = (!this.state.ranked) ? `/matchup/${user.id}/${other.id}` : `/matchup/${user.id}/${other.id}/ranked`
        }

        const buildStats = (u, o) => {

            return [
                {
                    title: u.userName,
                    value: userWins
                },
                {
                    title: o.userName,
                    value: otherWins
                }
            ]
        }

        const userRow = (u) => {
            return (
                <Grid item xs={12} >
                    <Typography variant="h2" className={classes.text} style={{fontWeight: "500", textAlign: "center"}}>
                        {u.userName}
                    </Typography>
                    <Typography variant="h5" className={classes.text} style={{textAlign: "center"}}>
                        {u.elo}
                    </Typography>
                </Grid>
            )
        }

        return (
            <Grid container className={classes.root}>
                {(!loading && user != undefined) && 
                        <Grid item xs={12} >
                            <Grid container className={classes.header} spacing={2}>
                                {userRow(user)}
                                <Grid item xs={12} >
                                    <Typography variant="h1" className={classes.text} style={{fontWeight: "800", textAlign: "center"}}>
                                        VS
                                    </Typography>
                                </Grid>
                                {userRow(other)}

                            </Grid>
                            <Grid container className={classes.userData}>
                                <StatBox stats={buildStats(user, other)} />
                            </Grid>
                            <div className={classes.header}>
                                <Typography variant="h2" className={classes.text}>
                                    Match History
                                </Typography>
                            </div>


                            <Matches linkPath={`/matchup/${user.id}/${other.id}`} skipRequest={true} />
                        </Grid> 
                }
                {!loading && matches.length == 0 && 
                    <Typography variant="h3" color="textPrimary" >NO MATCHUP UP DATA AVAILABLE BETWEEN USERS CHOSEN</Typography>
                }
                {loading && 
                    <Typography variant="h1" color="textPrimary" >LOADING</Typography>
                }
            </Grid>

            

        )
    }
}

export default withStyles(styles)(Matchup);