import {connect} from "react-redux";
import {GetUserAccount} from "../../Actions/userAccountActions"
import DrillCreator from "../Components/DrillCreator";
import build from 'redux-object';
import { CALL_API, API_ROOT } from "../../Middleware/Api";

const mapStateToProps = (state, ownProps) => {
    const {id} = ownProps.match.params;

    const {meta} = state.data;

    const link = `${API_ROOT}/drills/${id}`

    console.log("IS IT GETTING HERE?")

    if(meta[link]){
        let drill, drillId;
        
        console.log(meta[link].loading)
        console.log(drill)

        if(!meta[link].loading && meta[link].data && meta[link].data.length > 0){
            drillId = meta[link].data[0].id;
            
            drill = build(state.data, 'drills', drillId);
        }
        console.log(drill)

        return {drill, loading: meta[link].loading, userId: drillId}
    }

    return {drill: null, loading: true, drillId: id};
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDrill: () => {
            const {id} = ownProps.match.params;
            dispatch({
                [CALL_API]: {
                    endpoint: `${API_ROOT}/drills/${id}`
                }
            })
        }
    }
}


const DrillShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrillCreator);

export default DrillShow;