import React, { Component } from 'react';
import NavLink from "../Link"
import HeaderContainer from "./HeaderContainer";
import logo from "./logo2.png";

import compose from 'recompose/compose';

import { withStyles, withWidth, AppBar, Hidden, Toolbar } from '@material-ui/core';


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.lightBlack,
  },
  header: {
    justifyContent: "space-between",
  },
  flexGrow: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: "center"
  },    
  link: {
    ...theme.typography.title,
    margin: theme.spacing(2),
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  logo: {
    height: "100px",
    width: "100px"
  },
  active: {
    color: theme.palette.action.active,
  },
});

const LogoLink = React.forwardRef((props, ref) => (
  <img ref={ref} src={logo} alt="Logo" style={{height: "24px", width: "140px"}}/>
))

class Header extends Component{
  render(){
    const {classes} = this.props;
    return (
      
      <AppBar className={classes.root}>
        <Toolbar className={classes.header}>
          <NavLink to="/" component={LogoLink} />
          <Hidden smDown>
              <div className={classes.flexGrow}>
              </div>
          </Hidden>
          <HeaderContainer />                                   
        </Toolbar>
      </AppBar>

    )
  }
}

export default compose(withStyles(styles), withWidth())(Header);