import { 
    EditorState,
    convertFromRaw,
    convertToRaw    
} from 'draft-js';

import {RES_UPLOAD_URL, UPDATE_EDITOR_STATE, RESET_EDITOR_STATE, SET_EDITOR_STATE, SET_IMAGE_URL} from "../Actions/formActions";

const initialState = {
    editorState: EditorState.createEmpty(),
    upload: {
        url: null,
        fields: null
    },
    imgUrl: null
}


export default (state = initialState, action) => {
    switch (action.type){
        case UPDATE_EDITOR_STATE:
            return {
                ...state, 
                editorState: action.editorState,
                rawState: convertToRaw(action.editorState.getCurrentContent())
            }
        case RESET_EDITOR_STATE:
            return {
                ...state,
                editorState: EditorState.createEmpty()
            }
        case SET_EDITOR_STATE:
            if(action.rawContent){
                return {
                    ...state,
                    editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(action.rawContent)))
                }
            }
            else{
                return {
                    ...state,
                    editorState: EditorState.createEmpty()
                }
            }
        case RES_UPLOAD_URL: 
            return {
                ...state,
                upload: { 
                    ...action.upload
                }
            }
        case SET_IMAGE_URL:
            return {
                ...state, 
                imgUrl: action.imgUrl
            }
        default: 
            return state;
    }
}