import React from 'react';
import {connect} from "react-redux";
import compose from 'recompose/compose';

import { withStyles, AppBar, Toolbar, IconButton, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import Error from "./Login/error.js";

import {Close} from '@material-ui/icons';

import DialogTitle from '@material-ui/core/DialogTitle';


const styles = theme => ({
    content: {

    },
    flex: {
        flex: 1,
    },
    appBar: {
        position: 'absolute',
        backgroundColor: 'transparent',
        zIndex: 1000      
    }
})

class AuthForm extends React.Component {

    render(){
        const {classes, children, actions, closeForm, closeMessage, open, error, text} = this.props;
        return (
            <Dialog open={open} onClose={closeForm}>
                <DialogTitle id="form-dialog-title">                            
                    {text}
                </DialogTitle>
                <DialogContent className={classes.content}> 
                    { children }
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>              
                <Error message={error} close={closeMessage} />                    
            </Dialog>        
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        open: ownProps.open,
        error: state.Auth.errorAuth,        
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeMessage: () => {
            return dispatch({type: "READ_ERROR"})
        }
    }
}


const enhance = connect(
    mapStateToProps,
    mapDispatchToProps
  );

const AuthContainer = compose(enhance, withStyles(styles))(AuthForm)
  
export default AuthContainer;
  