import {connect} from "react-redux";
import {GetUserAccountMatches} from "../../Actions/userAccountActions"
import { CALL_API, API_ROOT } from "../../Middleware/Api";

import UserMatchupPage from "../Components/Matchup";
import build from 'redux-object';
import moment from "moment";


const defaultProps = (matches, loading, user, other, userWins, otherWins) => {
    return {
        matches: matches,
        loading,
        user,
        other,
        userWins,
        otherWins
    }
}

const mapStateToProps = (state, ownProps) => {

    const {userId, otherId} = ownProps.match.params;

    const {meta} = state.data;

    const link = `${API_ROOT}/matchup/${userId}/${otherId}`


    if(meta[link]){
        
        var user, other;

        var userWins = 0;
        var otherWins = 0;
        
        let matches = (meta[link].data || []).map(m => {
            var match = build(state.data, 'matches', m.id);

            var homeScore = getHomeScore(match);
            var awayScore = getAwayScore(match);
            for(var i =0;i<match.homeTeam.length;i++){
                


                if(match.homeTeam[i].id == userId){
                    user = match.homeTeam[i];
                    
                    if(homeScore > awayScore){
                        userWins++;
                    }

                }

                if(match.homeTeam[i].id == otherId){
                    other = match.homeTeam[i];

                    if(homeScore > awayScore){
                        otherWins++;
                    }

                }

            }

            for(var i =0;i<match.awayTeam.length;i++){
                if(match.awayTeam[i].id == userId){
                    user = match.awayTeam[i];

                    if(awayScore > homeScore){
                        userWins++;
                    }

                }
                if(match.awayTeam[i].id == otherId){
                    other = match.awayTeam[i];


                    if(awayScore > homeScore){
                        otherWins++;
                    }

                }
            }




            return match;
        });

        return defaultProps(matches, meta[link].loading, user, other, userWins, otherWins )
    }

    return defaultProps([], true);
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMatchup: () => {
            const {userId, otherId} = ownProps.match.params;
            dispatch( {
                [CALL_API]: {
                    endpoint: `${API_ROOT}/matchup/${userId}/${otherId}`
                }
            })
        }
    }
}

const getHomeScore = (m) => {
    var score = 0;
    for(var i = 0; i< m.rounds.length; i++){
        var r = m.rounds[i];

        if(r.homeScore > r.awayScore){
            score++;
        }
    }
    return score;
}

const getAwayScore = (m) => {
    var score = 0;
    for(var i = 0; i< m.rounds.length; i++){
        var r = m.rounds[i];
        if(r.awayScore > r.homeScore){
            score++;
        }
    }
    return score;
}

const MatchupPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMatchupPage);

export default MatchupPage;