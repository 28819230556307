const defaultConfig = {
    oculusOrgId: "1755586464708748",
    oculusRedirect: "https://www.elevenvr.net/auth/oa/"
}

const dev = {
    wsServer: "http://localhost",
    videoId: "hWJ3q4lyl8M",
    baseUrl: "https://www.elevenvr.club",
    authUrl: "https://www.elevenvr.club/api/v1/auth",
    apiUrl: "https://api.elevenvr.com/dev",
    hubUrl: "http://ec2-34-217-78-86.us-west-2.compute.amazonaws.com",
    oculusRedirect: "https://app.elevenvr.com/auth/oa",

}

const prod = {
    wsServer: "http://localhost",
    videoId: "hWJ3q4lyl8M",
    baseUrl: "https://api3.elevenvr.com",
    baseUrlNew	: "https://api3.elevenvr.com",
    apiUrl: "https://api.elevenvr.com/dev",
    authUrl: "http://localhost:5555/api/v1/auth",
    hubUrl: "http://ec2-34-217-78-86.us-west-2.compute.amazonaws.com",
    oculusRedirect: "https://app.elevenvr.com/auth/oa/"
}

const envConfigs = process.env.NODE_ENV === 'development' ? dev : prod;

const config = { ...defaultConfig, ...envConfigs};

// http://ec2-54-183-213-149.us-west-1.compute.amazonaws.com

//"https://www.elevenvr.club"


export default config;