import * as messageTypes from "./wsActions";
import {USERLIST, PUBLICUSERINFO} from "../Actions/users.js"; 
import {GETMESSAGE} from "../Actions/messageAction";
// import Config from "../Config/Config.js";

export default (msg) => {
    if(msg.success && msg.success.length > 0) return ParseArray(msg);
    return parseSingle(msg);
}

function parseSingle(msg){
    let newMessage = {};
    let messageBody = (msg.messageBody) ? (msg.messageBody) : msg;
    newMessage.data = ParseMessage(messageBody);
    return newMessage;
}

function ParseArray(msg){
    let newMessage = {};
    newMessage.data = msg.success.map((oldMsg) => {
        return ParseMessage(oldMsg, msg.messageType);
    })
    return newMessage;
}

function ParseMessage(msg, msgType = msg.messageType){
    switch(msgType) {
        case GETMESSAGE:
        case messageTypes.PUBLIC_CHAT:
            if(msg.messageType === messageTypes.PUBLIC_CHAT){
                return parsedPublicMessage(msg);
            }
            break;
        case USERLIST:
            return parsedUser(msg);
        case PUBLICUSERINFO:
            return parsedUser(msg.success);
        default: 
            return null;
    }
}


function parsedPublicMessage(msg) {
    return {
        type: "messages",
        id: msg.mid,
        attributes: {
            body: msg.body,
            createdAt: new Date(parseInt(msg.timeStamp, 10)),
            authorName: msg.sourceName,
            authorId: msg.sourceId,
            targetId: msg.destinationId,
            links: {
                author: `/eleven/${msg.sourceId}`
            },            
        },
        relationships: {
            author: {
                data: {
                    id: msg.sourceId,
                    type: "sockets"
                }
            },
            target: {
                data: {
                    id: msg.destinationId,
                    type: "sockets"
                }
            }
        }
    }
}


function parsedUser(msg){
    let username = (msg.webUserName) ? msg.webUserName : (msg.name + " ");
    return {
        id: msg.uid,
        type: "sockets",
        attributes: {
            ELO: msg.ELO,
            friendStatus: msg.friendStatus,
            username: username,
            console: msg.console
        },
        relationships: {
            user: {
                data: {
                    id: msg.webUserName,
                    type: "users"
                }
            }
        }
    }
}
/*
    TODO: 
    Inconsistancy
        * Add friendStatus to getPublicUser response


*/