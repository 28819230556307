import Config from "../Config/Config"
const defaultHeaders = () => ( 
  new Headers({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': "*",
    "Auth-Token": localStorage.getItem("AUTH_TOKEN")
  })
);

const defaultOptions = (method) => (
    {
        method: method,
        mode: 'cors',
        cache: 'default',
        headers: defaultHeaders()  
    }
)



class ElevenClient {
    constructor(base){
        this.url = base;
    }
  
    post(url, options = {}) {
        if(!options){
            options = defaultOptions("POST");
        }

        if(options.method == null){
            options.method = "POST"
        }

        options.headers = defaultHeaders();

        if(options.body){
            options.body = JSON.stringify(options.body);
        }

        let endpoint = url;

        if(isValidUrl(url)){
            endpoint = url;
        }
        else {
            endpoint = this.url + url;
        }

        return fetch(endpoint, options)
                .then(response => response.json()
                    .then((json) => {
                        if(!response.ok){
                            return Promise.reject(json);
                        } 
                        // response.data = JSON.parse(response.body);
                        return json;
                    })
                )

    }

    get(url, options = {}){
        options = defaultOptions("GET");


        let endpoint = url;

        if(isValidUrl(url)){
            endpoint = url;
        }
        else {
            endpoint = this.url + url;
        }

        return fetch(endpoint, options).then((response) => {
            var contentType = response.headers.get("content-type");
            if(contentType && contentType.includes("application/json")) {
                
                return response.json();
            }
            throw new TypeError("Oops, we haven't got JSON!");        
        })
    }
}

const isValidUrl = urlString=> {
    try { 
        return Boolean(new URL(urlString)); 
    }
    catch(e){ 
        return false; 
    }
}

const Eleven = new ElevenClient(Config.baseUrl);

export const ElevenAPI = new ElevenClient(Config.apiUrl)

export default Eleven;