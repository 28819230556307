import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../Reducers'
import thunkMiddleware from 'redux-thunk';
import api from "../Middleware/Api";
import ws, {WebSocketReceive} from '../Middleware/Ws';
import Logger from "../Middleware/Logging";


const store = createStore(
    rootReducer,
    applyMiddleware(
      thunkMiddleware,
      api,
      ws,
      Logger,
      WebSocketReceive
    )
  );


export default store;