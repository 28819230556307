import {connect} from "react-redux";
import MainIcon from "./MainIcon.js";
import {ShowLogin, ShowRegistration, ShowLinking, Logout} from "../../Actions/auth.js";
const mapStateToProps = (state) => {
    return {
        currentUser: state.Auth.currentUser,
        authenticated: state.Auth.authenticated,
        authenticating: state.Auth.authenticating,
        me: state.Auth.me,
        accounts: state.Auth.accounts,
        linked: state.Auth.linked
    }
} 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleLogout: () => {
            dispatch( Logout() )
        },
        showLogin: () => {
            dispatch(ShowLogin(true))
        },
        showRegistration: () => {
            dispatch(ShowRegistration(true))
        },
        showLinking: () => {
            dispatch(ShowLinking(true))
        }

    }
}

const HeaderComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainIcon)

export default HeaderComponent