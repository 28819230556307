import Auth from "./auth.js";
import SideBar from "./sideBar.js";
import Category from "./category";
import Global from "./global"
import Editor from "./form";
import data from './data';
import { combineReducers } from 'redux'

export default combineReducers({
    Auth, 
    SideBar,
    Editor, 
    data,
    Category, 
    Global
})