import { CALL_API } from "../Middleware/Api";

export const CreateComment = (newComment) => {
    const {user_id, commentLink, comment} = newComment;
    return {
        [CALL_API]: {
            endpoint: commentLink,
            options: {
                method: "POST",
                body: JSON.stringify({
                    comment: {
                        comment: JSON.stringify(comment),
                        user_id
                    }
                })
            }
        }
    }
}

export const UpdateComment = (newComment) => {
    const { commentLink, comment } = newComment;    
    return {
        [CALL_API]: {
            endpoint: commentLink,
            options: {
                method: "PATCH",
                body: JSON.stringify({
                    comment: {
                        comment: JSON.stringify(comment)
                    }
                })
            }
        }
    }
}

export const GetComments = (link) => (dispatch, getState) => {
    const {data} = getState();
    if(data.meta[link]){
        return;
    }
    return dispatch({
        [CALL_API]: {
            endpoint: link
        }
    })
}
