import {connect} from "react-redux";
import {CreateAnnouncement} from "../../Actions/newsActions";
import AnnouncementForm from "./Components/announcementForm.js";

const announceProps = (state, ownProps, saving) => (
    {
        announcement:  {title: ""},
        saving,
        createEmpty: true,
        actionName: "Create Announcement Draft"
    }
)
const mapStateToProps = (state, ownProps) => {
    const {meta} = state.data;
    if(meta['/announcements']){
        return announceProps(state, ownProps, meta['/announcements'].saving);
    }
    return announceProps(state, ownProps);
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        saveAnnouncement: (announcement, publish) => {
            dispatch( CreateAnnouncement(announcement) )
                .then(result => {
                    console.log(result);
                    const {data} = result.response.meta[result.endpoint];
                    let id = data[0].id
                    ownProps.history.push(`/admin/posts/${id}/edit`)
                })
        }
    }
}


const NewAnnouncement = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementForm);

export default NewAnnouncement;