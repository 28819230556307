import {connect} from "react-redux";
import {GetUserAccountEloHistory} from "../../Actions/userAccountActions"
import EloHistory from "../Components/EloHistory";
import build from 'redux-object';
import moment from "moment";
import { CALL_API, API_ROOT } from "../../Middleware/Api";



const mapStateToProps = (state, ownProps) => {
    const {id} = ownProps;

    const {meta} = state.data;
    const link = `${API_ROOT}/accounts/${id}/elo-history`

    if(meta[link]){
        let highest = 1500;
        let lowest = 1500;
        let matches = (meta[link].data || []).map(m => {
            var m = build(state.data, 'userMatches', m.id)
            if(m.currentElo > highest){
                highest = m.currentElo;
            }
            if(m.currentElo < lowest){
                lowest = m.currentElo;
            }
            m.createdAt = moment(m.createdAt).calendar();
            return m;
        })

        return {
            matches, 
            loading: meta[link].loading,
            highest, 
            lowest
        }
    }

    return {matches: [], loading: true, highest: 1500, lowest: 1500};
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserEloHistory: () => {
            const {id} = ownProps;

            dispatch({
                [CALL_API]: {
                    endpoint: `${API_ROOT}/accounts/${id}/elo-history`
                }
            })
        }
    }
}


const Elo = connect(
  mapStateToProps,
  mapDispatchToProps
)(EloHistory);

export default Elo;