import React from "react";
import Moment from "moment"
import Vote from "../Community/Containers/Vote"
import {Link} from "react-router-dom";

import {Comment} from "@material-ui/icons";
import { withStyles, Typography, Badge, Card, CardHeader, CardActions, CardContent, CardActionArea } from '@material-ui/core';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper 
    },
    link: {
        textDecoration: 'none'
    },    
    content: {
        minHeight:"20px"
    },
    flexGrow: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-start',      
    },
    badge: {
        margin: theme.spacing(1),
    },  
    icon: {
        fill: theme.palette.text.secondary,
        height: 26,
        width: 26,
    },
    actionItems: {
        ...theme.typography.button,
        lineHeight: '1.4em',
        padding: `${ theme.spacing(1)}px ${ theme.spacing(2)}px`,   
        color: theme.palette.text.primary,             
    },
    subTitle: {
      marginBottom: 16,
      color: theme.palette.text.secondary,
    },
    card: {
        transition: theme.transitions.create(['background-color', 'box-shadow'], {
            duration: theme.transitions.duration.standard,
        }),        
        "&:hover" : {
            cursor: "pointer",
         
            backgroundColor: theme.palette.text.primary,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },            
        }
    }
  });
class ArticlePreview extends React.Component {
    render(){
        const {classes, article, link, author, actions} = this.props;
        return (
            <Card raised>       
                <CardActionArea component={Link} to={link}>    
                    <CardHeader 
                        title={article.title}
                        subheader={`Posted by ${article.author.username} ${Moment(article.updatedAt).fromNow()}`}
                    />          
                    <CardContent 
                        className={classes.content}
                    >
                        <Typography variant="body2" color="textSecondary" component="p" >
                            {limitStringSize(240, article.description)}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                    <CardActions disableSpacing>
                        <div className={classes.flexGrow}>
                            <Vote 
                                voteLink={article.links.votes}
                                votes={article.votes}
                            />
                            {actions &&
                                actions(article, classes)
                            }    
                        </div>
                        <Badge className={classes.badge} badgeContent={article.commentCount} color="primary">
                            <Comment className={classes.icon} color="action" />
                        </Badge>
                    </CardActions>
            </Card>
            
        )
    }
}


function limitStringSize(count, string){
    if(!string) return ''
    return string.slice(0, count) + (string.length > count ? " ..." : "")
}

export default withStyles(styles)(ArticlePreview);